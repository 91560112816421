import React from "react";
import "../pages/pages.css";
import { Box } from "@mui/material";
import Header from "../components/navbar/Header";

function RETProducts() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <Header open={open} title="Retail Products" toggleDrawer={toggleDrawer} />
      <div
        className="retProducts"
        style={{
          marginTop: "80px",
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
          height: "calc(100vh - 80px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f4f4f4", // Light background color
          borderRadius: "8px", // Rounded corners
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow
        }}
      >
        <h1
          style={{
            fontSize: "3rem", // Larger font size
            fontWeight: "bold", // Bold text
            color: "#333", // Darker text color
            textAlign: "center", // Center align text
            margin: 0, // Remove default margin
            padding: "20px", // Padding around text
          }}
        >
          COMING SOON
        </h1>
      </div>
    </Box>
  );
}

export default RETProducts;
