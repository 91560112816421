import {
  Button,
  Dialog,
  TextField,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Oval } from "react-loader-spinner";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

interface Item {
  _id: string;
  [key: string]: any;
}

function ManageItem({
  open,
  onClose,
  item,
  getItem,
  itemName,
  title,
  tableElement,
  addField,
  itemId,
}: ManageItemProps) {
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<Item[]>([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Item | undefined>(undefined);
  const [newItem, setNewItem] = useState("");

  const getItems = async () => {
    try {
      const res = await axios.get(`/data/get-${getItem}`, {
        headers: { Authorization: `Bearer ${user!.token}` },
      });

      const data = res.data;

      setItems(data);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const delay = 100;

      setLoading(true);

      const timeoutId = setTimeout(async () => {
        await getItems();
        setLoading(false);
      }, delay);

      return () => clearTimeout(timeoutId);
    };

    fetchData();
  }, []);

  const handleAddItem = async () => {
    try {
      setLoading(true);
      await axios.post(
        `/data/add-${item}`,
        { [addField]: newItem },
        { headers: { Authorization: `Bearer ${user!.token}` } }
      );
      toast.success(`${itemName} added successfully`);
      setNewItem("");
      await getItems();
      setLoading(false);
    } catch (error) {
      toast.error(`Failed to add ${item}`);
      setLoading(false);
    }
  };

  const handleDeleteItem = async () => {
    try {
      setLoading(true);
      await axios.delete(`/data/delete-${item}/${selectedItem![itemId]}`, {
        headers: { Authorization: `Bearer ${user!.token}` },
      });
      toast.success(`${itemName} deleted successfully`);
      setOpenDeleteDialog(false);
      await getItems();
      setLoading(false);
    } catch (error) {
      toast.error(`Failed to delete ${item}`);
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        PaperProps={{
          style: {
            height: "600px",
            overflow: "hidden",
          },
        }}
      >
        <DialogTitle>Manage {title}</DialogTitle>
        <DialogContent>
          {loading && (
            <div className="loader-overlay">
              <Oval color="blue" height={50} width={50} />
            </div>
          )}
          <div className="buttons">
            <Button
              variant="contained"
              color="success"
              onClick={() => setOpenAddDialog(true)}
              size="medium"
            >
              <AddIcon />
              Add {itemName}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={async () => {
                setLoading(true);
                await getItems();
                setLoading(false);
              }}
              size="medium"
            >
              <RefreshIcon />
              Refresh
            </Button>
          </div>
          <TableContainer
            style={{
              height: "400px",
              overflow: "auto",
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <TableSortLabel>{itemName}</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((el) => (
                  <StyledTableRow key={el._id}>
                    <StyledTableCell>{el[tableElement]}</StyledTableCell>
                    <StyledTableCell>
                      <FontAwesomeIcon
                        icon={faTrash}
                        color="red"
                        onClick={() => {
                          setSelectedItem(el);
                          setOpenDeleteDialog(true);
                        }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            onClick={onClose}
            color="error"
            variant="outlined"
            style={{ marginRight: "16px", marginTop: "16px" }}
            size="large"
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        PaperProps={{
          style: {
            width: "400px",
            maxWidth: "90vw",
            margin: "auto",
          },
        }}
      >
        <DialogTitle>Add New {itemName}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={`${itemName}`}
            fullWidth
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
            style={{ marginBottom: "16px" }}
          />
          <Button
            onClick={handleAddItem}
            color="success"
            variant="contained"
            style={{ marginRight: "16px" }}
            size="large"
            disabled={newItem.length <= 0}
          >
            Submit
          </Button>
          <Button
            onClick={() => setOpenAddDialog(false)}
            color="error"
            variant="outlined"
            size="large"
          >
            Done
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        PaperProps={{
          style: {
            width: "300px",
            maxWidth: "90vw",
            margin: "auto",
          },
        }}
      >
        <DialogTitle>Delete {itemName}</DialogTitle>
        <DialogContent>
          <p
            style={{
              marginBottom: "16px",
            }}
          >
            Are you sure you want to delete{" "}
            <strong> {selectedItem ? selectedItem[tableElement] : ""}?</strong>
          </p>
          <Button
            onClick={handleDeleteItem}
            color="error"
            variant="contained"
            style={{ marginRight: "16px" }}
          >
            Delete
          </Button>
          <Button
            onClick={() => setOpenDeleteDialog(false)}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default ManageItem;
