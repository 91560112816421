import React, { useState } from "react";
import "./pages.css";
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CategoryIcon from "@mui/icons-material/Category";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import GroupIcon from "@mui/icons-material/Group";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import PersonIcon from "@mui/icons-material/Person";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopyright,
  faMaximize,
  faWineBottle,
} from "@fortawesome/free-solid-svg-icons";
import { useAuthContext } from "../hooks/useAuthContext";
import Header from "../components/navbar/Header";
import ManageItem from "../components/modals/manageItemModals/ManageItem";
import ManageUsers from "../components/modals/ManageUsers";
import ManageBusses from "../components/modals/ManageBusses";
import ManageEBC from "../components/modals/manageItemModals/ManageEBC";
function Admin() {
  const { user } = useAuthContext();
  const [categoriesModalOpen, setCategoriesModalOpen] = useState(false);
  const [suppliersModalOpen, setSuppliersModalOpen] = useState(false);
  const [productSizesModalOpen, setProductSizesModalOpen] = useState(false);
  const [bussesModalOpen, setBussesModalOpen] = useState(false);
  const [usersModalOpen, setUsersModalOpen] = useState(false);
  const [rolesModalOpen, setRolesModalOpen] = useState(false);
  const [brandsModalOpen, setBrandsModalOpen] = useState(false);
  const [emptyBottleCartonModalOpen, setEmptyBottleCartonModalOpen] =
    useState(false);

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const list = [
    {
      id: 1,
      open: categoriesModalOpen,
      onClose: () => setCategoriesModalOpen(false),
      item: "category",
      itemId: "categoryId",
      getItem: "categories",
      itemName: "Category",
      title: "Product Categories",
      tableElement: "categoryName",
      addField: "categoryName",
    },
    {
      id: 2,
      open: suppliersModalOpen,
      onClose: () => setSuppliersModalOpen(false),
      item: "supplier",
      itemId: "supplierId",
      getItem: "suppliers",
      itemName: "Supplier",
      title: "Suppliers",
      tableElement: "supplierName",
      addField: "supplierName",
    },
    {
      id: 3,
      open: productSizesModalOpen,
      onClose: () => setProductSizesModalOpen(false),
      item: "productSize",
      itemId: "sizeId",
      getItem: "product-Sizes",
      itemName: "Product Size",
      title: "Product Sizes",
      tableElement: "size",
      addField: "size",
    },
    {
      id: 4,
      open: rolesModalOpen,
      onClose: () => setRolesModalOpen(false),
      item: "role",
      itemId: "roleId",
      getItem: "roles",
      itemName: "Role",
      title: "Roles",
      tableElement: "roleName",
      addField: "roleName",
    },
    {
      id: 5,
      open: brandsModalOpen,
      onClose: () => setBrandsModalOpen(false),
      item: "brand",
      itemId: "brandId",
      getItem: "brands",
      itemName: "Brand",
      title: "Brands",
      tableElement: "brandName",
      addField: "brandName",
    },
  ];

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <Header
        open={open}
        title="Original Products Ltd."
        toggleDrawer={toggleDrawer}
      />
      <div
        className="admin"
        style={{
          marginTop: "80px",
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
          height: "calc(100vh - 80px)",
        }}
      >
        <div className="sections">
          <div className="section">
            <List>
              <ListItemButton onClick={() => setCategoriesModalOpen(true)}>
                <ListItemIcon>
                  <CategoryIcon />
                </ListItemIcon>
                <ListItemText primary={"Categories"} />
              </ListItemButton>
              <ListItemButton onClick={() => setSuppliersModalOpen(true)}>
                <ListItemIcon>
                  <LocalShippingIcon />
                </ListItemIcon>
                <ListItemText primary={"Suppliers"} />
              </ListItemButton>
              <ListItemButton onClick={() => setProductSizesModalOpen(true)}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faMaximize} size="lg" />
                </ListItemIcon>
                <ListItemText primary={" Product Sizes"} />
              </ListItemButton>
              <ListItemButton onClick={() => setBussesModalOpen(true)}>
                <ListItemIcon>
                  <AirportShuttleIcon />
                </ListItemIcon>
                <ListItemText primary={"Buses"} />
              </ListItemButton>
              <ListItemButton
                onClick={() => setEmptyBottleCartonModalOpen(true)}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faWineBottle} size="lg" />
                </ListItemIcon>
                <ListItemText primary={"Empty Cartons"} />
              </ListItemButton>
              <ListItemButton onClick={() => setBrandsModalOpen(true)}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faCopyright} size="lg" />
                </ListItemIcon>
                <ListItemText primary={"Product Brands"} />
              </ListItemButton>
            </List>
            {list.map((el) => (
              <ManageItem
                key={el.id}
                open={el.open}
                onClose={el.onClose}
                item={el.item}
                getItem={el.getItem}
                itemName={el.itemName}
                tableElement={el.tableElement}
                addField={el.addField}
                title={el.title}
                itemId={el.itemId}
              />
            ))}
            <ManageBusses
              open={bussesModalOpen}
              onClose={() => setBussesModalOpen(false)}
            />
            <ManageEBC
              open={emptyBottleCartonModalOpen}
              onClose={() => setEmptyBottleCartonModalOpen(false)}
            />
          </div>
          <div className="section">
            <p></p>
          </div>
          <div className="section">
            <List>
              {user!.role === "Admin" ||
                (user!.role === "Super-Admin" && (
                  <ListItemButton onClick={() => setUsersModalOpen(true)}>
                    <ListItemIcon>
                      <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Users"} />
                  </ListItemButton>
                ))}
              <ListItemButton onClick={() => setRolesModalOpen(true)}>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={"Roles"} />
              </ListItemButton>
            </List>
            <ManageUsers
              open={usersModalOpen}
              onClose={() => setUsersModalOpen(false)}
            />
          </div>
        </div>
      </div>
    </Box>
  );
}

export default Admin;
