import { Box, Grid, List, ListItem, ListItemText } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../components/navbar/Header";
import { useAuthContext } from "../hooks/useAuthContext";
import axios from "axios";
import { Event } from "../constants/models/event";
import { formatDate } from "../constants/utils";
import Loader from "../components/Loader";
import { BusAuditRaw } from "../constants/models/busAudit";

interface PendingItem {
  itemDescription: string;
}

function Dashboard() {
  const canApproveAudit = ["Super-Admin", "Supervisor"];
  const { user } = useAuthContext();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [upcomingEvents, setUpcomingEvents] = useState<Event[]>([]);
  const [pendingItems, setPendingItems] = useState<PendingItem[]>([]);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const getEvents = async () => {
    try {
      const res = await axios.get("/data/get-events", {
        headers: { Authorization: `Bearer ${user!.token}` },
      });

      const events: Event[] = res.data;
      const now = new Date();

      const upcomingEvents = events
        .filter(
          (event: Event) =>
            new Date(event.eventDate) > now && !event.isCompleted
        )
        .sort(
          (a: Event, b: Event) =>
            new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime()
        );

      setUpcomingEvents(upcomingEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const getAudits = async () => {
    try {
      const res = await axios.get("/audit/get-bus-audits", {
        headers: { Authorization: `Bearer ${user!.token}` },
      });

      const pendingItemsRaw = res.data.filter(
        (audit: BusAuditRaw) => audit.approved === false
      );

      if (pendingItemsRaw.length > 0 && canApproveAudit.includes(user!.role)) {
        const newPendingItems = pendingItemsRaw.map((el: BusAuditRaw) => ({
          itemDescription: `Approve audit done on ${el.busName} by ${el.auditedBy}`,
        }));
        setPendingItems(newPendingItems);
      }
    } catch (error) {
      console.error("Error fetching audits:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const delay = 100;

      setLoading(true);

      const timeoutId = setTimeout(async () => {
        await getEvents();
        await getAudits();
        setLoading(false);
      }, delay);

      return () => clearTimeout(timeoutId);
    };

    fetchData();
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <Header
        open={open}
        title="Original Products Ltd."
        toggleDrawer={toggleDrawer}
      />
      <Loader loading={loading} />
      <div
        className="dashboard"
        style={{
          marginTop: "80px",
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
          height: "calc(100vh - 80px)",
        }}
      >
        {/* <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={8}>
            <div className="section">
              <h1>Dashboard</h1>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="section">
              <div className="section-item">
                <div className="upcoming-events">
                  <h2>Upcoming Events</h2>
                  <div className="events">
                    {upcomingEvents.map((el, i) => {
                      return (
                        <div className="event">
                          <p
                            key={i}
                            style={{ fontSize: "15px", fontWeight: "bold" }}
                          >
                            {el.busData?.busName} {el.eventDescription}
                          </p>
                          <p> {formatDate(el.eventDate)}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="section-item">
                <div className="pending-items">
                  <h2>Pending Items</h2>
                  <List>
                    {pendingItems.map((el, index) => (
                      <ListItem button key={index}>
                        <ListItemText primary={el.itemDescription} />
                      </ListItem>
                    ))}
                  </List>
                </div>
              </div>
            </div>
          </Grid>
        </Grid> */}
      </div>
    </Box>
  );
}

export default Dashboard;
