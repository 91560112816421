import {
  CssBaseline,
  Toolbar,
  IconButton,
  Typography,
  List,
  Divider,
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AppBar } from "./AppBar";
import { Drawer } from "./Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { mainListItems, secondaryListItems } from "./listItems";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useLogout } from "../../hooks/useLogOut";
import PersonIcon from "@mui/icons-material/Person";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { User } from "../../constants/models/user";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../Loader";

interface HeaderProps {
  toggleDrawer: () => void;
  open: boolean;
  title: string;
}

function Header({ toggleDrawer, open, title }: HeaderProps) {
  const { user } = useAuthContext();
  const { logout } = useLogout();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openChangePasswordDialog, setOpenChangePasswordDialog] =
    useState(false);

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    logout();
  };

  const handleChangePassword = () => {
    handleMenuClose();
  };

  return (
    <>
      <CssBaseline />
      <AppBar>
        <Toolbar sx={{ pr: "24px" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {title}
          </Typography>
          <div className="user">
            <Button color="inherit" onClick={handleMenuOpen}>
              {user?.requirePasswordChange ? (
                <PriorityHighIcon color="error" style={{ fontSize: "40px" }} />
              ) : (
                <PersonIcon style={{ marginRight: "5px" }} />
              )}
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {user?.name}
              </span>
              <ArrowDropDownIcon style={{ marginLeft: "5px" }} />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={() => setOpenChangePasswordDialog(true)}
                style={{ color: user?.requirePasswordChange ? "red" : "black" }}
              >
                Change Password
              </MenuItem>
              <MenuItem onClick={handleLogout}>Log Out</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <ChangePassword
        open={openChangePasswordDialog}
        onClose={() => setOpenChangePasswordDialog(false)}
        user={user}
        logout={handleLogout}
      />
      <Drawer variant="permanent" open={open} style={{ marginTop: "50px" }}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            {!open ? (
              <ChevronRightIcon onClick={toggleDrawer} />
            ) : (
              <ChevronLeftIcon onClick={toggleDrawer} />
            )}
          </IconButton>
        </Toolbar>
        <List component="nav">
          {mainListItems}
          <Divider sx={{ my: 1 }} />
          {secondaryListItems}
        </List>
      </Drawer>
    </>
  );
}
interface ChangePasswordProps {
  open: boolean;
  onClose: (open: boolean) => void;
  user: User | null;
  logout: () => void;
}

export function ChangePassword({
  open,
  onClose,
  user,
  logout,
}: ChangePasswordProps) {
  const [hasError, setHasError] = useState(true);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleClose = () => {
    onClose(false);
    setFormData({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setHasError(false);
    setError("");
  };

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const hasUppercase = /[A-Z]/.test(formData.newPassword);
    const hasLowercase = /[a-z]/.test(formData.newPassword);
    const hasNumber = /\d/.test(formData.newPassword);

    if (
      formData.newPassword !== formData.confirmPassword &&
      formData.newPassword.length !== 0
    ) {
      setHasError(true);
      setError("New passwords do not match.");
    } else if (
      formData.newPassword.length < 8 &&
      formData.newPassword.length !== 0
    ) {
      setHasError(true);
      setError("Password must be at least 8 characters long.");
    } else if (!hasUppercase || !hasLowercase || !hasNumber) {
      setHasError(true);
      setError(
        "Password must include at least one uppercase letter, one lowercase letter, and one number."
      );
    } else {
      setHasError(false);
      setError("");
    }
  }, [formData]);

  const handleChangePassword = async () => {
    if (!hasError) {
      setLoading(true);
      try {
        const res = await axios.post("/user/change-password", {
          userId: user!.userId,
          oldPassword: formData.oldPassword,
          newPassword: formData.newPassword,
        });
        toast.success("Password changed successfully!");
        setLoading(false);
        onClose(false);
        logout();
      } catch (error: any) {
        setLoading(false);
        if (error.response?.status === 401) {
          setHasError(true);
          setError("Your Old Password is Wrong!");
        } else if (error.response?.status === 400) {
          setHasError(true);
          setError(
            "Your Old Password can not be the same as your New Password!"
          );
        } else {
          toast.error("An error occurred while changing the password.");
        }
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: "500px",
          maxWidth: "90vw",
          margin: "auto",
          maxHeight: "80vh",
        },
      }}
    >
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <Loader loading={loading} />
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            marginTop: "10px",
          }}
        >
          <div className="">
            <TextField
              label="Old Password"
              type="password"
              value={formData.oldPassword}
              name="oldPassword"
              onChange={handleChange}
              fullWidth
              required
            />
          </div>
          <div
            className=""
            style={{ display: "flex", flexDirection: "column", gap: "2px" }}
          >
            <TextField
              label="New Password"
              type="password"
              value={formData.newPassword}
              onChange={handleChange}
              name="newPassword"
              fullWidth
              required
            />
          </div>
          <TextField
            label="Confirm New Password"
            value={formData.confirmPassword}
            name="confirmPassword"
            onChange={handleChange}
            type="password"
            fullWidth
            required
          />
          {hasError && <Alert severity="error">{error}</Alert>}
          {!hasError && (
            <Alert severity="warning" sx={{ mb: 1 }}>
              You will be logged out
            </Alert>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="error"
          variant="outlined"
          size="large"
        >
          Cancel
        </Button>
        <Button
          onClick={handleChangePassword}
          color="success"
          variant="contained"
          size="large"
        >
          Change Password
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Header;
