import {
  Button,
  Dialog,
  Select,
  MenuItem,
  DialogContent,
  TextField,
  IconButton,
  Grid,
  DialogActions,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import { Product, ProductArray } from "../../constants/models/product";
import RemoveIcon from "@mui/icons-material/Remove";
import "../../components/style.css";
import axios from "axios";
import { useAuthContext } from "../../hooks/useAuthContext";
import toast from "react-hot-toast";
function ManageReturnedItems({
  setReturnedProducts,
  setOpenReturnedItemsDialog,
  openReturnedItemsDialog,
}: any) {
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);

  const [items, setItems] = useState([
    { productId: "", price: 0, quantity: 1 },
  ]);
  const [isValid, setIsValid] = useState(false);

  const getProducts = async () => {
    if (user) {
      try {
        const res = await axios.get("/products/get-products", {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        const sortedData = res.data.sort((a: Product, b: Product) => {
          if (a.description < b.description) return -1;
          if (a.description > b.description) return 1;
          return b.quantity - a.quantity;
        });

        setProducts(sortedData);
      } catch (error: any) {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const delay = 100;

      setLoading(true);

      const timeoutId = setTimeout(async () => {
        await getProducts();
        setLoading(false);
      }, delay);

      return () => clearTimeout(timeoutId);
    };

    fetchData();
  }, []);

  const handleFieldChange = (index: number, field: any, value: any) => {
    const newItems: any = [...items];
    newItems[index][field] = value;
    setItems(newItems);
  };

  //   const handleProductChange = (index: number, productId: string) => {
  //     const selectedProduct = products.find((el) => el._id === productId);

  //     console.log(selectedProduct);

  //     if (selectedProduct) {
  //       const newItems = [...items];
  //       newItems[index] = {
  //         ...newItems[index],
  //         productId,
  //         price: selectedProduct.price,
  //       };
  //       setItems(newItems);
  //     } else {
  //       toast.error("Product not found");
  //     }
  //   };

  const handleProductChange = (
    event: any,
    value: Product | null,
    index: number
  ) => {
    if (value) {
      const newItems = [...items];
      newItems[index] = {
        ...newItems[index],
        productId: value.productId,
        price: value.price,
      };
      setItems(newItems);
      console.log(newItems);
    } else {
      toast.error("Product not found");
    }
  };

  useEffect(() => {
    const allItemsValid = items.every(
      (item) => item.productId && item.price > 0 && item.quantity > 0
    );
    setIsValid(allItemsValid);
  }, [items]);

  const addRow = () => {
    setItems([...items, { productId: "", price: 0, quantity: 1 }]);
  };

  const removeRow = (index: any) => {
    if (items.length > 1) {
      const newItems = items.filter((_, i) => i !== index);
      setItems(newItems);
    }
  };

  const handleSave = () => {
    setReturnedProducts(items);
    setOpenReturnedItemsDialog(false);
  };

  return (
    <Dialog
      open={openReturnedItemsDialog}
      onClose={() => setOpenReturnedItemsDialog(false)}
      PaperProps={{
        style: {
          width: "1000px",
          maxWidth: "90vw",
          margin: "auto",
        },
      }}
    >
      <DialogContent>
        {items.map((item, index) => (
          <Grid
            container
            spacing={2}
            key={index}
            style={{ marginBottom: "16px" }}
          >
            <Grid item xs={8}>
              <Autocomplete
                options={products}
                getOptionLabel={(option) =>
                  `${option.size || ""} - ${option.brandName || ""} ${
                    option.description || ""
                  }`
                }
                value={
                  products.find(
                    (product) => product.productId === item.productId
                  ) || null
                }
                onChange={(event, newValue) =>
                  handleProductChange(event, newValue, index)
                }
                renderInput={(params) => (
                  <TextField {...params} label="Product" variant="outlined" />
                )}
                isOptionEqualToValue={(option, value) =>
                  option._id === value?._id
                }
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                type="number"
                label="Quantity"
                value={item.quantity}
                onChange={(e) =>
                  handleFieldChange(index, "quantity", e.target.value)
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              {index === items.length - 1 && (
                <IconButton
                  onClick={addRow}
                  style={{
                    color: "green",
                    cursor: "pointer",
                    fontSize: "30px",
                  }}
                >
                  <AddIcon />
                </IconButton>
              )}
              <IconButton
                onClick={() => removeRow(index)}
                disabled={items.length === 1}
                sx={{
                  color: items.length === 1 ? "gray" : "red",
                  cursor: items.length === 1 ? "not-allowed" : "pointer",
                  fontSize: "30px",
                }}
              >
                <RemoveIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSave}
          variant="outlined"
          color="error"
          size="large"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="success"
          size="large"
          disabled={!isValid}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ManageReturnedItems;
