import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../pages/pages.css";
import {
  Button,
  Dialog,
  TextField,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Header from "../components/navbar/Header";
import AuditResult from "../components/modals/AuditResult";
import { ProductArray } from "../constants/models/product";
import { ArrowBack, ArrowBackIos } from "@mui/icons-material";
function AuditBus() {
  const navigate = useNavigate();
  const location = useLocation();
  const [productsInBus, setProductsInBus] = useState<ProductArray[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [inExcess, setInExcess] = useState<{ [key: string]: boolean }>({});
  const { bus, productData } = location.state || {};

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (Array.isArray(productData)) {
      const groupedProducts = productData.reduce((acc, curr) => {
        const { _id } = curr.productId;

        if (!acc[_id]) {
          acc[_id] = { ...curr };
        } else {
          acc[_id].quantity += curr.quantity;
        }

        return acc;
      }, {} as { [key: string]: ProductArray });

      setProductsInBus(Object.values(groupedProducts));
    }
  }, [productData]);

  const handlePhysicalCountChange = (event: any, id: string) => {
    const value = Number(event.target.value);

    if (value < 0) {
      return;
    }

    const systemProduct = systemCount.find(
      (product) => product.productId._id === id
    );

    if (systemProduct && value <= systemProduct.quantity) {
      setInExcess((prevState) => ({ ...prevState, [id]: false }));
      const updatedProducts = productsInBus.map((product) =>
        product.productId._id === id ? { ...product, quantity: value } : product
      );
      setProductsInBus(updatedProducts);
    } else {
      setInExcess((prevState) => ({ ...prevState, [id]: true }));
      const updatedProducts = productsInBus.map((product) =>
        product.productId._id === id ? { ...product, quantity: value } : product
      );
      setProductsInBus(updatedProducts);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const systemCount: ProductArray[] = useMemo(() => {
    if (Array.isArray(productData)) {
      const groupedProducts = productData.reduce((acc, curr) => {
        const { _id } = curr.productId;
        if (!acc[_id]) {
          acc[_id] = { ...curr };
        } else {
          acc[_id].quantity += curr.quantity;
        }

        return acc;
      }, {} as { [key: string]: ProductArray });

      return Object.values(groupedProducts);
    }

    return [];
  }, [productData]);

  const physicalCount = productsInBus;

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <Header open={open} title="Audit Bus" toggleDrawer={toggleDrawer} />
      <div
        className="auditBus"
        style={{
          marginTop: "80px",
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
          height: "calc(100vh - 80px)",
        }}
      >
        <div className="buttons">
          <Button
            variant="contained"
            color="secondary"
            style={{ width: "200px", height: "40px" }}
            onClick={() => navigate(-1)}
            size="large"
          >
            <ArrowBack />
            Bus Details
          </Button>
          <Button
            variant="contained"
            color="success"
            style={{ width: "200px", height: "40px" }}
            onClick={handleOpenDialog}
          >
            Start Audit
          </Button>
        </div>
        <div className="sections">
          <div className="section">
            <h1>System Count</h1>
            <div className="section-items">
              {systemCount.map((el) => (
                <div className="items" key={el.productId._id}>
                  <p className="item-quantity">{el.quantity}</p>
                  <p className="item-size">{el.size.size}</p>
                  <p className="item-desc">{el.productId.description}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="section">
            <h1>Physical Count</h1>
            <div className="section-items">
              {physicalCount.map((el) => (
                <div
                  className="items"
                  key={el.productId._id}
                  style={{
                    color: inExcess[el.productId._id] ? "red" : "inherit",
                  }}
                >
                  <TextField
                    className="item-count"
                    type="number"
                    value={el.quantity || 0}
                    onChange={(e) =>
                      handlePhysicalCountChange(e, el.productId._id)
                    }
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ min: 0 }}
                  />
                  <p className="item-size">{el.size.size}</p>
                  <p className="item-desc">
                    {el.brand.brandName + " " + el.productId.description}
                  </p>
                  {inExcess[el.productId._id] && <p>IS EXCESS</p>}
                </div>
              ))}
            </div>
          </div>
        </div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="xl"
        >
          <DialogTitle>Audit Result</DialogTitle>
          <DialogContent>
            <AuditResult
              physicalCount={physicalCount}
              systemCount={systemCount}
              bus={bus}
              onClose={handleCloseDialog}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDialog}
              color="error"
              size="large"
              variant="outlined"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Box>
  );
}

export default AuditBus;
