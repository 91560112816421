import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import LiquorIcon from "@mui/icons-material/Liquor";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import ManageDamagedItems from "./ManageDamagedItems";
import axios from "axios";
import toast from "react-hot-toast";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Bus } from "../../constants/models/bus";
import { ProductArray } from "../../constants/models/product";
import ManageAuditEBC from "./ManageAuditEBC";
import { formatCurrency, formatDate } from "../../constants/utils";
import { KeyboardReturn } from "@mui/icons-material";
import ManageReturnedItems from "./ManageReturnedItems";

function AuditResult({
  bus,
  physicalCount,
  systemCount,
  onClose,
}: AuditResultProps) {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const location = useLocation();
  const [soldProducts, setSoldProducts] = useState<ProductArray[]>([]);
  const [damagedProducts, setDamagedProducts] = useState<any>([]);
  const [returnedProducts, setReturnedProducts] = useState<any>([]);
  const [emptyBottleCartoons, setEmptyBottleCartoons] = useState<any>([]);
  const [costOfDamagedProducts, setCostOfDamagedProducts] = useState(0);
  const [costOfReturnedProducts, setCostOfReturnedProducts] = useState(0);
  const [totalSold, setTotalSold] = useState(0);
  const [cashCount, setCashCount] = useState(0);
  const [endTotal, setEndTotal] = useState(0);
  const [difference, setDifference] = useState(0);
  const [emptyBottleAllowance, setEmptyBottleAllowance] = useState(0);
  const [openEBCDialog, setOpenEBCDialog] = useState(false);
  const [openDamagedItemsDialog, setOpenDamagedItemsDialog] = useState(false);
  const [openReturnedItemsDialog, setOpenReturnedItemsDialog] = useState(false);
  const [notes, setNotes] = useState("");

  const valueHeadCells = useMemo(
    () => [
      { id: "description", label: "Description" },
      { id: "quantity", label: "# Sold" },
      { id: "size", label: "Size" },
      { id: "price", label: "Price" },
      { id: "total", label: "Total" },
    ],
    []
  );

  const today = new Date();
  const startDate = new Date(
    new Date(bus.lastAudited).getTime() + 24 * 60 * 60 * 1000
  );

  const physicalCountArray = useMemo(
    () => (Array.isArray(physicalCount) ? physicalCount : []),
    [physicalCount]
  );
  const systemCountArray = useMemo(
    () => (Array.isArray(systemCount) ? systemCount : []),
    [systemCount]
  );

  useEffect(() => {
    const physicalCountMap = new Map();
    physicalCountArray.forEach((item) => {
      physicalCountMap.set(item.productId._id, item.quantity);
    });

    const updatedSoldProducts: ProductArray[] = systemCountArray
      .map((item) => {
        const physicalQuantity = physicalCountMap.get(item.productId._id) || 0;
        const remainder = item.quantity - physicalQuantity;
        return remainder > 0 ? { ...item, remainder } : null;
      })
      .filter(Boolean) as ProductArray[];

    setSoldProducts(updatedSoldProducts);

    const total = updatedSoldProducts.reduce(
      (acc, item) => acc + item.productId.price * item.quantity,
      0
    );
    setTotalSold(total);
  }, [physicalCount, systemCount]);

  const handleCashCountChange = (e: any) => {
    setCashCount(parseFloat(e.target.value));
  };

  useEffect(() => {
    const costOfDamaged = damagedProducts.reduce(
      (total: any, item: any) => total + item.price * item.quantity,
      0
    );
    setCostOfDamagedProducts(costOfDamaged);

    const costOfReturned = returnedProducts.reduce(
      (total: any, item: any) => total + item.price * item.quantity,
      0
    );
    setCostOfReturnedProducts(costOfReturned);

    const allowance = emptyBottleCartoons.reduce(
      (total: any, item: any) => total + item.price * item.quantity,
      0
    );
    setEmptyBottleAllowance(allowance);

    const endTotalValue = totalSold - (costOfDamagedProducts + allowance);
    setEndTotal(endTotalValue);

    const differenceValue = cashCount - endTotalValue;
    setDifference(differenceValue);
  }, [
    damagedProducts,
    returnedProducts,
    emptyBottleCartoons,
    totalSold,
    costOfDamagedProducts,
    cashCount,
  ]);

  const handleEndAudit = async () => {
    const dateRange = {
      startDate: startDate,
      endDate: today,
    };

    try {
      const body = {
        soldProducts: soldProducts.map((product) => ({
          productId: product.productId._id,
          quantity: product.quantity,
        })),
        busId: bus.busId,
        dateRange,
        damagedProducts,
        cashCount,
        totalSold,
        costOfDamagedProducts,
        allowanceForEmptyBottles: emptyBottleAllowance,
        endTotal,
        difference,
        auditedBy: user?.userId,
        notes: notes,
      };

      await axios.post("/audit/add-bus-audit", body, {
        headers: { Authorization: `Bearer ${user!.token}` },
      });
      onClose();
      navigate("/manage-busses");
      toast.success("Audit ended successfully");
    } catch (error) {
      toast.error("Failed to end audit");
    }
  };

  return (
    <div className="auditResults">
      <div
        className="header"
        style={{
          display: "flex",
          alignItems: "center",
          width: "48%",
          justifyContent: "space-between",
        }}
      >
        <h1>Sold Items</h1>
        <h2>Total: {formatCurrency(totalSold)}</h2>
      </div>
      <div className="sections">
        <div className="section">
          <div className="section-items">
            <TableContainer
              style={{
                height: "700px",
                overflow: "auto",
                position: "relative",
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead style={{ color: "#ff9900" }}>
                  <TableRow>
                    {valueHeadCells.map((el) => (
                      <StyledTableCell key={el.id}>
                        <TableSortLabel>{el.label}</TableSortLabel>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {soldProducts.map((product) => (
                    <StyledTableRow key={product.productId._id}>
                      <StyledTableCell>
                        {product.productId.description}
                      </StyledTableCell>
                      <StyledTableCell>{product.quantity}</StyledTableCell>
                      <StyledTableCell>{product.size.size}</StyledTableCell>
                      <StyledTableCell>
                        {formatCurrency(product.productId.price)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {formatCurrency(
                          product.productId.price * product.quantity
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <div className="section">
          <div className="buttons" style={{ marginBottom: "30px" }}>
            <Button
              color="error"
              variant="outlined"
              onClick={() => setOpenReturnedItemsDialog(true)}
            >
              <KeyboardReturn style={{ marginRight: "5px" }} />
              Add Returned
            </Button>
            <Button
              color="error"
              variant="outlined"
              onClick={() => setOpenDamagedItemsDialog(true)}
            >
              <BrokenImageIcon style={{ marginRight: "5px" }} />
              Add Damaged
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setOpenEBCDialog(true)}
            >
              <LiquorIcon style={{ marginRight: "5px" }} />
              Add Empty
            </Button>
          </div>
          <p className="date">{`Audit Period: ${formatDate(
            startDate
          )} - ${formatDate(today)}`}</p>
          <div className="section-item" style={{ marginBottom: "50px" }}>
            <p className="item-label">Physical Cash Count</p>
            <TextField
              className="item-count"
              type="number"
              value={cashCount}
              onChange={handleCashCountChange}
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: 0 }}
            />
          </div>
          <div className="section-item">
            <p className="item-label">Total Sold</p>
            {formatCurrency(totalSold)}
          </div>
          <div className="section-item">
            <p className="item-label">Cost of Damaged Products</p>
            {formatCurrency(costOfDamagedProducts)}
          </div>
          <div className="section-item">
            <p className="item-label">Cost of Returned Products</p>
            {formatCurrency(costOfReturnedProducts)}
          </div>
          <div className="section-item">
            <p className="item-label">Allowance for Empty Bottle Cartoons</p>
            {formatCurrency(emptyBottleAllowance)}
          </div>
          <div className="section-item">
            <p className="item-label">End Total</p>
            {formatCurrency(endTotal)}
          </div>
          <div className="section-item" style={{ marginTop: "50px" }}>
            <p className="item-label">Cash Count</p>
            {formatCurrency(cashCount)}
          </div>
          <div className="section-item">
            <p className="item-label">Difference</p>
            <p
              style={{
                color:
                  difference > 0 ? "green" : difference === 0 ? "amber" : "red",
              }}
            >
              {formatCurrency(difference)}
            </p>
          </div>
          <div className="" style={{ width: "50%" }}>
            <TextField
              sx={{ mb: 2 }}
              placeholder="Notes/Reason For Difference"
              fullWidth
              multiline
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              rows={3}
            />
          </div>
          <Button
            size="large"
            color="success"
            variant="contained"
            onClick={handleEndAudit}
          >
            End Audit
          </Button>
        </div>
      </div>
      <ManageAuditEBC
        setEmptyBottleCartoons={setEmptyBottleCartoons}
        setOpenEBCDialog={setOpenEBCDialog}
        openEBCDialog={openEBCDialog}
      />
      <ManageDamagedItems
        setDamagedProducts={setDamagedProducts}
        setOpenDamagedItemsDialog={setOpenDamagedItemsDialog}
        openDamagedItemsDialog={openDamagedItemsDialog}
        systemCount={systemCount}
      />
      <ManageReturnedItems
        setReturnedProducts={setReturnedProducts}
        setOpenReturnedItemsDialog={setOpenReturnedItemsDialog}
        openReturnedItemsDialog={openReturnedItemsDialog}
        systemCount={systemCount}
      />
    </div>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const FooterTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: 16,
}));

export default AuditResult;
