import {
  Button,
  Dialog,
  DialogContent,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Autocomplete,
  Alert,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import "../../pages/pages.css";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Size } from "../../constants/models/size";
import { Category } from "../../constants/models/category";
import { Supplier } from "../../constants/models/supplier";
import { Oval } from "react-loader-spinner";
import { Brand } from "../../constants/models/brand";

function AddProduct({ open, onClose, onProductAdded }: AddProductProps) {
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [sizes, setSizes] = useState<Size[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [selectedSizes, setSelectedSizes] = useState<SelectedSizes>({});
  const [formData, setFormData] = useState({
    description: "",
    price: "",
    suggestedRetailPrice: 0.0,
    itemQuantity: "",
    sizeId: "",
    categoryId: "",
    supplierId: "",
    brandId: "",
  });
  const [errors, setErrors] = useState({
    description: "",
    price: "",
    suggestedRetailPrice: "",
    itemQuantity: "",
    categoryId: "",
    supplierId: "",
    sizes: "",
    brandId: "",
  });

  const handleInputChange = (name: string) => (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const newData = {
        ...prevData,
        [name]: value,
      };
      return newData;
    });
  };

  const getData = async () => {
    try {
      const categoriesRes = await axios.get("/data/get-categories", {
        headers: { Authorization: `Bearer ${user!.token}` },
      });
      const suppliersRes = await axios.get("/data/get-suppliers", {
        headers: { Authorization: `Bearer ${user!.token}` },
      });
      const sizesRes = await axios.get("/data/get-product-sizes", {
        headers: { Authorization: `Bearer ${user!.token}` },
      });
      const brandsRes = await axios.get("/data/get-brands", {
        headers: { Authorization: `Bearer ${user!.token}` },
      });

      const sortedSizes = sizesRes.data.sort((a: Size, b: Size) => {
        const order = [1, 0.5, 0.25, 0.1, 0.01];
        return order.indexOf(a.no) - order.indexOf(b.no);
      });

      const sortedBrands = brandsRes.data.sort((a: Brand, b: Brand) => {
        if (a.brandName < b.brandName) {
          return -1;
        }
        if (a.brandName > b.brandName) {
          return 1;
        }
        return 0;
      });

      const sortedCat = categoriesRes.data.sort((a: Category, b: Category) => {
        if (a.categoryName < b.categoryName) {
          return -1;
        }
        if (a.categoryName > b.categoryName) {
          return 1;
        }
        return 0;
      });

      setCategories(sortedCat);
      setSuppliers(suppliersRes.data);
      setSizes(sortedSizes);
      setBrands(sortedBrands);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const delay = 100;

      setLoading(true);

      const timeoutId = setTimeout(async () => {
        await getData();
        setLoading(false);
      }, delay);

      return () => clearTimeout(timeoutId);
    };

    fetchData();
  }, []);

  const handleCheckboxChange = (sizeId: string) => (e: any) => {
    const { checked } = e.target;
    setSelectedSizes((prevSelectedSizes: any) => ({
      ...prevSelectedSizes,
      [sizeId]: checked ? { price: "", itemQuantity: "" } : undefined,
    }));
  };

  const handlePriceChange = (sizeId: string) => (e: any) => {
    const { value } = e.target;
    setSelectedSizes((prevSelectedSizes: any) => ({
      ...prevSelectedSizes,
      [sizeId]: {
        ...(prevSelectedSizes[sizeId] || {}),
        price: value,
      },
    }));
  };

  const handleQuantityChange = (sizeId: string) => (e: any) => {
    const { value } = e.target;
    setSelectedSizes((prevSelectedSizes: any) => ({
      ...prevSelectedSizes,
      [sizeId]: {
        ...(prevSelectedSizes[sizeId] || {}),
        itemQuantity: value,
      },
    }));
  };

  const handleAddProduct = async () => {
    try {
      const productPromises = Object.keys(selectedSizes).map(
        async (sizeId: string) => {
          const sizeData = selectedSizes[sizeId];
          if (sizeData !== undefined) {
            const productData: any = {
              description: formData.description,
              price: sizeData.price,
              suggestedRetailPrice: formData.suggestedRetailPrice,
              itemQuantity: sizeData.itemQuantity,
              sizeId,
              categoryId: formData.categoryId,
              supplierId: formData.supplierId,
              brandId: formData.brandId,
            };

            await axios.post("/products/add-product", productData, {
              headers: { Authorization: `Bearer ${user!.token}` },
            });
          }
        }
      );

      await Promise.all(productPromises);
      setFormData({
        description: "",
        price: "",
        suggestedRetailPrice: 0.0,
        itemQuantity: "",
        sizeId: "",
        categoryId: "",
        supplierId: "",
        brandId: "",
      });
      setSelectedSizes({});
      toast.success("Products added successfully!");
      onProductAdded();
      onClose();
    } catch (error) {
      toast.error("Failed to add products. Please try again.");
    }
  };

  const validateForm = () => {
    let isFormValid = true;
    const newErrors = { ...errors };

    if (formData.description.trim() === "") {
      newErrors.description = "Description is required.";
      isFormValid = false;
    } else {
      newErrors.description = "";
    }

    if (formData.price.trim() === "") {
      newErrors.price = "Price is required.";
      isFormValid = false;
    } else {
      newErrors.price = "";
    }

    if (formData.itemQuantity.trim() === "") {
      newErrors.itemQuantity = "Item Quantity is required.";
      isFormValid = false;
    } else {
      newErrors.itemQuantity = "";
    }

    if (formData.categoryId.trim() === "") {
      newErrors.categoryId = "Category is required.";
      isFormValid = false;
    } else {
      newErrors.categoryId = "";
    }

    if (formData.brandId.trim() === "") {
      newErrors.brandId = "Brand is required.";
      isFormValid = false;
    } else {
      newErrors.brandId = "";
    }

    if (formData.supplierId.trim() === "") {
      newErrors.supplierId = "Supplier is required.";
      isFormValid = false;
    } else {
      newErrors.supplierId = "";
    }

    if (Object.keys(selectedSizes).length === 0) {
      newErrors.sizes = "At least one size must be selected.";
      isFormValid = false;
    } else {
      let allSizesValid = true;
      for (const [sizeId, sizeData] of Object.entries(selectedSizes)) {
        if (sizeData != undefined) {
          if (
            !sizeData ||
            isNaN(sizeData.price) ||
            sizeData.price <= 0 ||
            isNaN(sizeData.itemQuantity) ||
            sizeData.itemQuantity <= 0
          ) {
            newErrors.sizes =
              "All sizes must have a valid price and item quantity.";
            allSizesValid = false;
            break;
          }
        }
      }
      if (allSizesValid) {
        newErrors.sizes = "";
        isFormValid = true;
      }
    }

    setErrors(newErrors);
    return isFormValid;
  };

  useEffect(() => {
    setIsValid(validateForm());
  }, [formData, selectedSizes]);

  const handleModalClose = () => {
    setFormData({
      description: "",
      price: "",
      suggestedRetailPrice: 0.0,
      itemQuantity: "",
      sizeId: "",
      categoryId: "",
      supplierId: "",
      brandId: "",
    });
    setSelectedSizes({});
    setErrors({
      description: "",
      price: "",
      suggestedRetailPrice: "",
      itemQuantity: "",
      categoryId: "",
      supplierId: "",
      sizes: "",
      brandId: "",
    });
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        style: {
          width: "1000px",
          maxWidth: "90vw",
          margin: "auto",
        },
      }}
    >
      <DialogTitle style={{ fontSize: "30px" }}>Add New Product</DialogTitle>
      <DialogContent>
        {loading && (
          <div className="loader-overlay">
            <Oval color="blue" height={50} width={50} />
          </div>
        )}
        <form className="form">
          <Grid container spacing={2}>
            <Grid item xs={4} sx={{ mt: 3 }}>
              <Autocomplete
                options={brands}
                getOptionLabel={(option) => `${option.brandName}`}
                value={
                  brands.find((brand) => brand.brandId === formData.brandId) ||
                  null
                }
                onChange={(event, newValue) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    brandId: newValue ? newValue.brandId : "",
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Brand"
                    variant="outlined"
                    error={!!errors.brandId}
                    helperText={errors.brandId}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8} sx={{ mt: 3 }}>
              <TextField
                label="Description"
                name="description"
                value={formData.description}
                onChange={handleInputChange("description")}
                fullWidth
                error={!!errors.description}
                helperText={errors.description}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <FormControl fullWidth>
                <Autocomplete
                  options={categories}
                  getOptionLabel={(option) => `${option.categoryName}`}
                  value={
                    categories.find(
                      (category) => category.categoryId === formData.categoryId
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      categoryId: newValue ? newValue.categoryId : "",
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Category"
                      variant="outlined"
                      error={!!errors.categoryId}
                      helperText={errors.categoryId}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ mt: 1 }}>
              <FormControl fullWidth>
                <Autocomplete
                  options={suppliers}
                  getOptionLabel={(option) => `${option.supplierName}`}
                  value={
                    suppliers.find(
                      (supplier) => supplier.supplierId === formData.supplierId
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      supplierId: newValue ? newValue.supplierId : "",
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Supplier"
                      variant="outlined"
                      error={!!errors.supplierId}
                      helperText={errors.supplierId}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ mt: 1 }}>
              <TextField
                label="Suggested Retail Price"
                name="suggestedRetailPrice"
                value={formData.suggestedRetailPrice}
                onChange={handleInputChange("suggestedRetailPrice")}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <FormControl fullWidth>
                {sizes.map((size) => (
                  <Grid container alignItems="center" key={size.sizeId}>
                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!selectedSizes[size.sizeId]}
                            onChange={handleCheckboxChange(size.sizeId)}
                            name={size.size}
                          />
                        }
                        label={size.size}
                      />
                    </Grid>
                    {selectedSizes[size.sizeId] && (
                      <>
                        <Grid item xs={4} sx={{ mb: 2, mr: 2 }}>
                          <TextField
                            label="Price"
                            name={`price_${size.sizeId}`}
                            value={selectedSizes[size.sizeId]?.price || ""}
                            onChange={handlePriceChange(size.sizeId)}
                            type="number"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={3} sx={{ mb: 2 }}>
                          <TextField
                            label="Item Quantity"
                            name={`quantity_${size.sizeId}`}
                            value={
                              selectedSizes[size.sizeId]?.itemQuantity || ""
                            }
                            onChange={handleQuantityChange(size.sizeId)}
                            type="number"
                            fullWidth
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                ))}
                {errors.sizes && <Alert severity="error">{errors.sizes}</Alert>}
              </FormControl>
            </Grid>
          </Grid>
        </form>
        <div style={{ marginTop: "20px" }}>
          <Button
            onClick={handleAddProduct}
            color="success"
            variant="contained"
            style={{ marginRight: "12px" }}
            size="large"
            disabled={loading || !isValid}
          >
            Submit
          </Button>
          <Button
            onClick={handleModalClose}
            color="error"
            variant="outlined"
            size="large"
          >
            Cancel
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default AddProduct;
