import {
  Button,
  Dialog,
  TextField,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Grid,
} from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Oval } from "react-loader-spinner";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { EBC } from "../../../constants/models/ebc";

function ManageEBC({ open, onClose }: ManageEBCProps) {
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [ebc, setEBC] = useState<EBC[]>([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [newEBC, setNewEBC] = useState({ cartonName: "", price: 0 });
  const [selectedEBC, setSelectedEBC] = useState<EBC>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const formatCurrency = (value: number) => {
    return value != null
      ? new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "XCD",
        }).format(value)
      : "N/A";
  };

  const getEBC = async () => {
    try {
      const res = await axios.get("/data/get-empty-bottle-cartons", {
        headers: { Authorization: `Bearer ${user!.token}` },
      });
      const data: EBC[] = res.data;
      setEBC(data);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const delay = 100;

      setLoading(true);

      const timeoutId = setTimeout(async () => {
        await getEBC();
        setLoading(false);
      }, delay);

      return () => clearTimeout(timeoutId);
    };

    fetchData();
  }, []);

  const handleAddEBC = async () => {
    if (!newEBC.cartonName || !newEBC.price) {
      toast.error("Carton name and price are required");
      return;
    }

    try {
      await axios.post(
        "/data/add-empty-bottle-carton",
        { cartonName: newEBC.cartonName, price: newEBC.price },
        { headers: { Authorization: `Bearer ${user!.token}` } }
      );
      toast.success("Carton added successfully");
      setNewEBC({ cartonName: "", price: 0 });
      await getEBC();
      setOpenAddDialog(false);
    } catch (error) {
      toast.error("Failed to add Carton");
      console.error(error);
    }
  };

  const handleDeleteEBC = async () => {
    try {
      await axios.delete(
        `/data/delete-empty-bottle-carton/${selectedEBC!.cartonId}`,
        { headers: { Authorization: `Bearer ${user!.token}` } }
      );
      toast.success("Bus deleted successfully");
      setOpenDeleteDialog(false);
      await getEBC();
    } catch (error) {
      toast.error("Failed to delete Carton");
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        PaperProps={{
          style: {
            height: "600px",
            overflow: "hidden",
          },
        }}
      >
        <DialogTitle>Manage Empty Cartons</DialogTitle>
        <DialogContent>
          {loading && (
            <div className="loader-overlay">
              <Oval color="blue" height={50} width={50} />
            </div>
          )}
          <div className="buttons">
            <Button
              variant="contained"
              color="success"
              onClick={() => setOpenAddDialog(true)}
            >
              <AddIcon />
              Add Empty Carton
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={async () => {
                setLoading(true);
                await getEBC();
                setLoading(false);
              }}
            >
              <RefreshIcon />
              Refresh
            </Button>
          </div>
          <TableContainer
            style={{
              height: "400px",
              overflow: "auto",
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <TableSortLabel>Carton Name</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel>Carton Price</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ebc.map((el) => (
                  <StyledTableRow key={el.cartonId}>
                    <StyledTableCell>{`${el.cartonName}`}</StyledTableCell>
                    <StyledTableCell>
                      {formatCurrency(el.price)}
                    </StyledTableCell>
                    <StyledTableCell>
                      <FontAwesomeIcon
                        icon={faTrash}
                        color="red"
                        onClick={() => {
                          setSelectedEBC(el);
                          setOpenDeleteDialog(true);
                        }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            onClick={onClose}
            color="error"
            variant="outlined"
            style={{ marginRight: "16px", marginTop: "16px" }}
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        PaperProps={{
          style: {
            width: "500px",
            maxWidth: "90vw",
            margin: "auto",
          },
        }}
      >
        <DialogTitle>Add New Empty Carton</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={9}>
              <TextField
                autoFocus
                margin="dense"
                label="Carton Name"
                fullWidth
                style={{ marginBottom: "16px" }}
                value={newEBC.cartonName}
                onChange={(e) =>
                  setNewEBC({ ...newEBC, cartonName: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                margin="dense"
                label="Price"
                fullWidth
                style={{ marginBottom: "16px" }}
                value={newEBC.price}
                onChange={(e) =>
                  setNewEBC({ ...newEBC, price: parseFloat(e.target.value) })
                }
              />
            </Grid>
          </Grid>
          <Button
            onClick={handleAddEBC}
            color="success"
            variant="contained"
            style={{ marginRight: "16px" }}
          >
            Add
          </Button>
          <Button
            onClick={() => setOpenAddDialog(false)}
            color="error"
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        PaperProps={{
          style: {
            width: "300px",
            maxWidth: "90vw",
            margin: "auto",
          },
        }}
      >
        <DialogTitle>Delete Empty Carton</DialogTitle>
        <DialogContent>
          <p
            style={{
              marginBottom: "16px",
            }}
          >
            Are you sure you want to delete{" "}
            <strong> {selectedEBC?.cartonName}?</strong>
          </p>
          <Button
            onClick={handleDeleteEBC}
            color="error"
            variant="contained"
            style={{ marginRight: "16px" }}
          >
            Delete
          </Button>
          <Button
            onClick={() => setOpenDeleteDialog(false)}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default ManageEBC;
