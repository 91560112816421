import {
  Button,
  Dialog,
  Select,
  MenuItem,
  DialogContent,
  TextField,
  IconButton,
  Grid,
  DialogActions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import { ProductArray } from "../../constants/models/product";
import RemoveIcon from "@mui/icons-material/Remove";
import "../../components/style.css";
function ManageDamagedItems({
  setDamagedProducts,
  setOpenDamagedItemsDialog,
  openDamagedItemsDialog,
  systemCount,
}: any) {
  const [items, setItems] = useState([
    { productId: "", price: 0, quantity: 1 },
  ]);
  const [isValid, setIsValid] = useState(false);

  const handleFieldChange = (index: number, field: any, value: any) => {
    const newItems: any = [...items];
    newItems[index][field] = value;
    setItems(newItems);
  };

  const handleProductChange = (index: number, productId: any) => {
    const selectedProduct: ProductArray = systemCount.find(
      (el: ProductArray) => el.productId._id === productId
    );
    if (selectedProduct) {
      const newItems = [...items];
      newItems[index] = {
        ...newItems[index],
        productId,
        price: selectedProduct.productId.price,
      };
      setItems(newItems);
    }
  };

  useEffect(() => {
    const allItemsValid = items.every(
      (item) => item.productId && item.price > 0 && item.quantity > 0
    );
    setIsValid(allItemsValid);
  }, [items]);

  const addRow = () => {
    setItems([...items, { productId: "", price: 0, quantity: 1 }]);
  };

  const removeRow = (index: any) => {
    if (items.length > 1) {
      const newItems = items.filter((_, i) => i !== index);
      setItems(newItems);
    }
  };

  const handleSave = () => {
    setDamagedProducts(items);
    setOpenDamagedItemsDialog(false);
  };

  return (
    <Dialog
      open={openDamagedItemsDialog}
      onClose={() => setOpenDamagedItemsDialog(false)}
      PaperProps={{
        style: {
          width: "1000px",
          maxWidth: "90vw",
          margin: "auto",
        },
      }}
    >
      <DialogContent>
        {items.map((item, index) => (
          <Grid
            container
            spacing={2}
            key={index}
            style={{ marginBottom: "16px" }}
          >
            <Grid item xs={8}>
              <Select
                value={item.productId}
                onChange={(e) => handleProductChange(index, e.target.value)}
                fullWidth
              >
                {systemCount.map((el: any) => (
                  <MenuItem key={el.productId._id} value={el.productId._id}>
                    {`${el.size.size} - ${el.brand.brandName} ${el.productId.description}`}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={2}>
              <TextField
                type="number"
                label="Quantity"
                value={item.quantity}
                onChange={(e) =>
                  handleFieldChange(index, "quantity", e.target.value)
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              {index === items.length - 1 && (
                <IconButton
                  onClick={addRow}
                  style={{
                    color: "green",
                    cursor: "pointer",
                    fontSize: "30px",
                  }}
                >
                  <AddIcon />
                </IconButton>
              )}
              <IconButton
                onClick={() => removeRow(index)}
                disabled={items.length === 1}
                sx={{
                  color: items.length === 1 ? "gray" : "red",
                  cursor: items.length === 1 ? "not-allowed" : "pointer",
                  fontSize: "30px",
                }}
              >
                <RemoveIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSave}
          variant="outlined"
          color="error"
          size="large"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="success"
          size="large"
          disabled={!isValid}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ManageDamagedItems;
