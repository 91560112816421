import {
  Button,
  Dialog,
  TextField,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Oval } from "react-loader-spinner";
import { useAuthContext } from "../../hooks/useAuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Bus } from "../../constants/models/bus";

function ManageBusses({ open, onClose }: ManageBussesProps) {
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [busses, setBusses] = useState<Bus[]>([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [newBusName, setNewBusName] = useState("");
  const [selectedBus, setSelectedBus] = useState<Bus>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const getBusses = async () => {
    try {
      const res = await axios.get("/bus/get-busses", {
        headers: { Authorization: `Bearer ${user!.token}` },
      });
      const data = res.data;
      setBusses(data);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const delay = 100;

      setLoading(true);

      const timeoutId = setTimeout(async () => {
        await getBusses();
        setLoading(false);
      }, delay);

      return () => clearTimeout(timeoutId);
    };

    fetchData();
  }, []);

  const handleAddBus = async () => {
    try {
      await axios.post(
        "/bus/add-bus",
        { busName: newBusName },
        { headers: { Authorization: `Bearer ${user!.token}` } }
      );
      toast.success("Bus added successfully");
      setNewBusName("");
      await getBusses();
    } catch (error) {
      toast.error("Failed to add Bus");
    }
  };

  const handleDeleteBus = async () => {
    try {
      await axios.delete(`/bus/delete-bus/${selectedBus!.busId}`, {
        headers: { Authorization: `Bearer ${user!.token}` },
      });
      toast.success("Bus deleted successfully");
      setOpenDeleteDialog(false);
      await getBusses();
    } catch (error) {
      toast.error("Failed to delete Bus");
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        PaperProps={{
          style: {
            height: "600px",
            overflow: "hidden",
          },
        }}
      >
        <DialogTitle>Manage Busses</DialogTitle>
        <DialogContent>
          {loading && (
            <div className="loader-overlay">
              <Oval color="blue" height={50} width={50} />
            </div>
          )}
          <div className="buttons">
            <Button
              variant="contained"
              color="success"
              onClick={() => setOpenAddDialog(true)}
            >
              <AddIcon />
              Add Bus
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={async () => {
                setLoading(true);
                await getBusses();
                setLoading(false);
              }}
            >
              <RefreshIcon />
              Refresh
            </Button>
          </div>
          <TableContainer
            style={{
              height: "400px",
              overflow: "auto",
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <TableSortLabel>Bus</TableSortLabel>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {busses.map((el) => (
                  <StyledTableRow key={el.busId}>
                    <StyledTableCell>{`${el.busName}`}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            onClick={onClose}
            color="error"
            variant="outlined"
            style={{ marginRight: "16px", marginTop: "16px" }}
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
        <DialogTitle>Add New Bus</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Bus Name"
            fullWidth
            value={newBusName}
            onChange={(e) => setNewBusName(e.target.value)}
            style={{ marginBottom: "16px" }}
          />
          <Button
            onClick={handleAddBus}
            color="success"
            variant="contained"
            style={{ marginRight: "16px" }}
          >
            Add
          </Button>
          <Button
            onClick={() => setOpenAddDialog(false)}
            color="error"
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        PaperProps={{
          style: {
            width: "300px",
            maxWidth: "90vw",
            margin: "auto",
          },
        }}
      >
        <DialogTitle>Delete Bus</DialogTitle>
        <DialogContent>
          <p
            style={{
              marginBottom: "16px",
            }}
          >
            Are you sure you want to delete{" "}
            <strong> {selectedBus?.busName}?</strong>
          </p>
          <Button
            onClick={handleDeleteBus}
            color="error"
            variant="contained"
            style={{ marginRight: "16px" }}
          >
            Delete
          </Button>
          <Button
            onClick={() => setOpenDeleteDialog(false)}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default ManageBusses;
