import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import HomeIcon from "@mui/icons-material/Home";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import SettingsIcon from "@mui/icons-material/Settings";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxesStacked } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const ListItems = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <ListItemButton onClick={() => navigate("/dashboard")}>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary={"Home"} />
      </ListItemButton>
      {/* <ListItemButton onClick={() => navigate("/inventory")}>
        <ListItemIcon>
          <WarehouseIcon />
        </ListItemIcon>
        <ListItemText primary={"Inventory"} />
      </ListItemButton> */}
      <ListItemButton onClick={() => navigate("/whls-products")}>
        <ListItemIcon>
          <FontAwesomeIcon icon={faBoxesStacked} size="lg" />
        </ListItemIcon>
        <ListItemText primary={"Products - WHLS"} />
      </ListItemButton>
      {/* <ListItemButton onClick={() => navigate("/ret-products")}>
        <ListItemIcon>
          <Inventory2Icon />
        </ListItemIcon>
        <ListItemText primary={"Products - RET"} />
      </ListItemButton> */}
      <ListItemButton onClick={() => navigate("/manage-busses")}>
        <ListItemIcon>
          <AirportShuttleIcon />
        </ListItemIcon>
        <ListItemText primary={"Busses"} />
      </ListItemButton>
      <ListItemButton onClick={() => navigate("/admin")}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary={"Admin"} />
      </ListItemButton>
    </React.Fragment>
  );
};

export const mainListItems = <ListItems />;

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Reports
    </ListSubheader>
  </React.Fragment>
);
