import axios from "axios";
import { Toaster } from "react-hot-toast";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { useAuthContext } from "./hooks/useAuthContext";
import React, { useEffect } from "react";
import LoginPage from "./pages/LoginPage";
import WHLSProducts from "./pages/WHLSProducts";
import Admin from "./pages/Admin";
import AuditBus from "./pages/AuditBus";
import Busses from "./pages/Busses";
import RETProducts from "./pages/RETProducts";
import Dashboard from "./pages/Dashboard";
import Inventory from "./pages/Inventory";
import { Analytics } from "@vercel/analytics/react";
import { AuthContextProvider } from "./context/AuthContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = "https://oplis-api.onrender.com";
} else {
  axios.defaults.baseURL = "http://localhost:5001";
}

axios.defaults.withCredentials = true;

function App() {
  const { user } = useAuthContext();
  const location = useLocation();

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem("currentPath", location.pathname);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location.pathname]);

  const storedPath = localStorage.getItem("currentPath") || "/dashboard";

  return (
    <div className="App">
      <Analytics />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Toaster position="top-right" toastOptions={{ duration: 4000 }} />
        <div className="content-wrapper" style={{ backgroundColor: "#f0f0f0" }}>
          <div className="content">
            <Routes>
              <Route
                path="/"
                element={
                  !user ? (
                    <LoginPage />
                  ) : (
                    <Navigate
                      to={storedPath === "/" ? "/dashboard" : storedPath}
                    />
                  )
                }
              />
              <Route
                path="/dashboard"
                element={user ? <Dashboard /> : <Navigate to="/" />}
              />
              <Route
                path="/whls-products"
                element={user ? <WHLSProducts /> : <Navigate to="/" />}
              />
              <Route
                path="/ret-products"
                element={user ? <RETProducts /> : <Navigate to="/" />}
              />
              <Route
                path="/admin"
                element={user ? <Admin /> : <Navigate to="/" />}
              />
              <Route
                path="/manage-busses"
                element={user ? <Busses /> : <Navigate to="/" />}
              />
              <Route
                path="/audit-bus"
                element={user ? <AuditBus /> : <Navigate to="/" />}
              />
              <Route
                path="/inventory"
                element={user ? <Inventory /> : <Navigate to="/" />}
              />
            </Routes>
          </div>
        </div>
      </LocalizationProvider>
    </div>
  );
}

export default App;
