import {
  Button,
  Dialog,
  Select,
  MenuItem,
  DialogContent,
  TextField,
  IconButton,
  Grid,
  DialogActions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useAuthContext } from "../../hooks/useAuthContext";
import { EBC } from "../../constants/models/ebc";
import RemoveIcon from "@mui/icons-material/Remove";

function ManageAuditEBC({
  setEmptyBottleCartoons,
  setOpenEBCDialog,
  openEBCDialog,
}: ManageAuditEBCProps) {
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [ebc, setEBC] = useState<EBC[]>([]);
  const [items, setItems] = useState<Item[]>([{ price: 0, quantity: 1 }]);
  const [isValid, setIsValid] = useState(false);

  const getEBC = async () => {
    try {
      const res = await axios.get("/data/get-empty-bottle-cartons", {
        headers: { Authorization: `Bearer ${user!.token}` },
      });
      setEBC(res.data);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(async () => {
      await getEBC();
      setLoading(false);
    }, 100);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const allItemsValid = items.every(
      (item) => item.price > 0 && item.quantity > 0
    );
    setIsValid(allItemsValid);
  }, [items]);

  const handleFieldChange = (index: number, field: keyof Item, value: any) => {
    const newItems: any = [...items];
    newItems[index][field] = value;
    setItems(newItems);
  };

  const addRow = () => {
    setItems([...items, { price: 0, quantity: 1 }]);
  };

  const removeRow = (index: number) => {
    if (items.length > 1) {
      const newItems = items.filter((_, i) => i !== index);
      setItems(newItems);
    }
  };

  const handleSave = () => {
    if (isValid) {
      const transformedItems: any = items.map((item) => ({
        price: item.price,
        quantity: item.quantity,
      }));
      setEmptyBottleCartoons(transformedItems);
      setOpenEBCDialog(false);
    } else {
      toast.error("Please ensure all fields are filled out correctly.");
    }
  };

  const handleCloseDialog = () => {
    setOpenEBCDialog(false);
  };

  return (
    <Dialog
      open={openEBCDialog}
      onClose={() => setOpenEBCDialog(false)}
      PaperProps={{
        style: {
          width: "700px",
          maxWidth: "90vw",
          margin: "auto",
        },
      }}
    >
      <DialogContent>
        {items.map((item, index) => (
          <Grid
            container
            spacing={2}
            key={index}
            style={{ marginBottom: "16px" }}
          >
            <Grid item xs={8}>
              <Select
                value={item.price}
                onChange={(e) =>
                  handleFieldChange(index, "price", e.target.value)
                }
                fullWidth
              >
                {ebc.map((el) => (
                  <MenuItem key={el.cartonId} value={el.price}>
                    {el.cartonName}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={2}>
              <TextField
                type="number"
                label="Quantity"
                value={item.quantity}
                onChange={(e) =>
                  handleFieldChange(index, "quantity", e.target.value)
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={2} display="flex" alignItems="center">
              <IconButton
                onClick={addRow}
                style={{
                  color: "green",
                  cursor: "pointer",
                  fontSize: "30px",
                }}
              >
                <AddIcon />
              </IconButton>
              <IconButton
                onClick={() => removeRow(index)}
                disabled={items.length === 1}
                sx={{
                  color: items.length === 1 ? "gray" : "red",
                  cursor: items.length === 1 ? "not-allowed" : "pointer",
                  fontSize: "30px",
                }}
              >
                <RemoveIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          color="error"
          variant="outlined"
          size="large"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          color="success"
          variant="contained"
          size="large"
          disabled={!isValid}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ManageAuditEBC;
