import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import axios from "axios";

export const useLogIn = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { dispatch } = useAuthContext();

  const login = async (userName: String, password: String) => {
    try {
      setLoading(true);
      const res = await axios.post("/user/login-user", { userName, password });
      const data = res.data;
      localStorage.setItem("user", JSON.stringify(data));
      dispatch({ type: "LOGIN", payload: data });
      setLoading(false);
    } catch (error: any) {
      console.error(error);
      setError(error.response.data.error);
      setLoading(false);
    }
  };

  return { login, error, loading };
};
