export const formatDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "short",
  };
  return new Date(date).toLocaleDateString(undefined, options);
};

export const formatCurrency = (value: number) => {
  return value != null
    ? new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "XCD",
      }).format(value)
    : "N/A";
};
