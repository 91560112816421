import React, { useState, useEffect } from "react";
import { Oval } from "react-loader-spinner";
import toast from "react-hot-toast";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  DialogTitle,
  Box,
  Autocomplete,
  InputAdornment,
  DialogActions,
  styled,
  tableCellClasses,
  TableSortLabel,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  TableContainer,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import EditIcon from "@mui/icons-material/Edit";
import "./pages.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuthContext } from "../hooks/useAuthContext";
import { Product } from "../constants/models/product";
import { Size } from "../constants/models/size";
import Header from "../components/navbar/Header";
import AddProduct from "../components/modals/AddProduct";
import { Supplier } from "../constants/models/supplier";
import { Category } from "../constants/models/category";
import { Brand } from "../constants/models/brand";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { formatCurrency } from "../constants/utils";
import SearchIcon from "@mui/icons-material/Search";

function WHLSProducts() {
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [sizes, setSizes] = useState<Size[]>([]);
  const [updatedProduct, setUpdatedProduct] = useState({
    description: "",
    categoryId: "",
    brandId: "",
    itemQuantity: 0,
    price: 0,
    sizeId: "",
    supplierId: "",
    suggestedRetailPrice: 0.0,
  });

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const getProducts = async () => {
    if (user) {
      try {
        const res = await axios.get("/products/get-products", {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        const sortedData = res.data.sort((a: Product, b: Product) => {
          if (a.description < b.description) return -1;
          if (a.description > b.description) return 1;
          return b.quantity - a.quantity;
        });

        setProducts(sortedData);
      } catch (error: any) {
        toast.error(error.message);
      }
    }
  };

  const getData = async () => {
    if (user) {
      try {
        const categoriesRes = await axios.get("/data/get-categories", {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        const suppliersRes = await axios.get("/data/get-suppliers", {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        const sizesRes = await axios.get("/data/get-product-sizes", {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        const brandsRes = await axios.get("/data/get-brands", {
          headers: { Authorization: `Bearer ${user!.token}` },
        });

        const sortedSizes = sizesRes.data.sort((a: Size, b: Size) => {
          const order = [1, 0.5, 0.25];
          return order.indexOf(a.no) - order.indexOf(b.no);
        });

        const sortedBrands = brandsRes.data.sort((a: Brand, b: Brand) => {
          if (a.brandName < b.brandName) {
            return -1;
          }
          if (a.brandName > b.brandName) {
            return 1;
          }
          return 0;
        });

        setCategories(categoriesRes.data);
        setSuppliers(suppliersRes.data);
        setSizes(sortedSizes);
        setBrands(sortedBrands);
      } catch (error: any) {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const delay = 100;

      setLoading(true);

      const timeoutId = setTimeout(async () => {
        await getProducts();
        await getData();
        setLoading(false);
      }, delay);

      return () => clearTimeout(timeoutId);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filtered = products.filter(
      (product) =>
        product.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.brandName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.categoryName
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        product.supplierName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [searchQuery, products]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (selectedProduct) {
      setUpdatedProduct({
        description: selectedProduct.description || "",
        categoryId: selectedProduct.categoryId || "",
        itemQuantity: selectedProduct.itemQuantity || 0,
        price: selectedProduct.price || 0,
        sizeId: selectedProduct.sizeId || "",
        supplierId: selectedProduct.supplierId || "",
        suggestedRetailPrice: selectedProduct.suggestedRetailPrice || 0.0,
        brandId: selectedProduct.brandId || "",
      });
    }
  }, [selectedProduct]);

  const handleDeleteProduct = async () => {
    try {
      setLoading(true);
      await axios.delete(
        `/products/delete-product/${selectedProduct!.productId}`,
        {
          headers: { Authorization: `Bearer ${user!.token}` },
        }
      );
      toast.success("Product Deleted Successfully");
      setOpenDeleteDialog(false);
      await getProducts();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const handleUpdateProduct = async () => {
    if (selectedProduct) {
      try {
        setLoading(true);
        const updatedData = {
          productId: selectedProduct.productId,
          description: updatedProduct.description,
          categoryId: updatedProduct.categoryId,
          itemQuantity: updatedProduct.itemQuantity,
          price: updatedProduct.price,
          sizeId: updatedProduct.sizeId,
          supplierId: updatedProduct.supplierId,
          suggestedRetailPrice: updatedProduct.suggestedRetailPrice,
          brandId: updatedProduct.brandId,
        };

        await axios.put(
          `/products/update-product/${selectedProduct.productId}`,
          updatedData,
          { headers: { Authorization: `Bearer ${user!.token}` } }
        );
        toast.success("Product Updated Successfully");
        setOpenUpdateDialog(false);
        await getProducts();
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        toast.error(error.message);
      }
    }
  };

  const handleFieldChange = (event: any) => {
    const { name, value } = event.target;
    setUpdatedProduct((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <Header
        open={open}
        title="Wholesale Products"
        toggleDrawer={toggleDrawer}
      />
      {loading && (
        <div className="loader-overlay">
          <Oval color="blue" height={50} width={50} />
        </div>
      )}
      <div
        className="product-screen"
        style={{
          marginTop: "80px",
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
          height: "calc(100vh - 80px)",
        }}
      >
        <div className="buttons">
          <Button
            variant="contained"
            color="success"
            size="large"
            onClick={() => setModalOpen(true)}
          >
            <AddIcon />
            Add Product
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={async () => {
              setLoading(true);
              await getProducts();
              await getData();
              setLoading(false);
            }}
          >
            <RefreshIcon />
            Refresh
          </Button>
        </div>
        <div className="search-bar">
          <TextField
            variant="outlined"
            fullWidth
            value={searchQuery}
            placeholder="Type to search"
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <WHLSProductsTable
          productData={filteredProducts}
          setOpenUpdateDialog={setOpenUpdateDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
          setSelectedProduct={setSelectedProduct}
          loading={loading}
        />
        <AddProduct
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onProductAdded={getProducts}
        />
        <UpdateDialog
          openUpdateDialog={openUpdateDialog}
          setOpenUpdateDialog={setOpenUpdateDialog}
          updatedProduct={updatedProduct}
          handleFieldChange={handleFieldChange}
          categories={categories}
          suppliers={suppliers}
          sizes={sizes}
          brands={brands}
          selectedProduct={selectedProduct}
          handleUpdateProduct={handleUpdateProduct}
          setUpdatedProduct={setUpdatedProduct}
        />
        <DeleteDialog
          openDeleteDialog={openDeleteDialog}
          selectedProduct={selectedProduct}
          handleDeleteProduct={handleDeleteProduct}
          setOpenDeleteDialog={setOpenDeleteDialog}
        />
      </div>
    </Box>
  );
}

export function DeleteDialog({
  openDeleteDialog,
  selectedProduct,
  handleDeleteProduct,
  setOpenDeleteDialog,
}: any) {
  return (
    <Dialog
      open={openDeleteDialog}
      onClose={() => setOpenDeleteDialog(false)}
      PaperProps={{
        style: {
          width: "400px",
          maxWidth: "90vw",
          margin: "auto",
        },
      }}
    >
      <DialogTitle>Confirm Product Deletion</DialogTitle>
      <DialogContent>
        <p style={{ marginBottom: "10px" }}>
          Are you sure you want to delete{" "}
          <strong>
            {selectedProduct
              ? selectedProduct.size + " - " + selectedProduct.description
              : "this product"}
          </strong>
          ?
        </p>
        <Button
          onClick={() => {
            if (selectedProduct) {
              handleDeleteProduct();
              setOpenDeleteDialog(false);
            }
          }}
          color="error"
          variant="contained"
          style={{ marginRight: "16px" }}
          size="large"
        >
          Delete
        </Button>
        <Button
          onClick={() => setOpenDeleteDialog(false)}
          color="primary"
          variant="outlined"
          size="large"
        >
          Cancel
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export function UpdateDialog({
  openUpdateDialog,
  setOpenUpdateDialog,
  updatedProduct,
  handleFieldChange,
  categories,
  suppliers,
  sizes,
  selectedProduct,
  handleUpdateProduct,
  brands,
  setUpdatedProduct,
}: any) {
  const [isSame, setIsSame] = useState(true);

  useEffect(() => {
    if (selectedProduct) {
      const isUpdated = Object.keys(updatedProduct).some(
        (key) => updatedProduct[key] !== selectedProduct[key]
      );
      setIsSame(!isUpdated);
    } else {
      setIsSame(true);
    }
  }, [updatedProduct, selectedProduct]);

  return (
    <Dialog
      open={openUpdateDialog}
      onClose={() => setOpenUpdateDialog(false)}
      PaperProps={{
        style: {
          width: "900px",
          maxWidth: "90vw",
          margin: "auto",
        },
      }}
    >
      <DialogTitle>Update Product</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={4} sx={{ mt: 3 }}>
            <Autocomplete
              options={brands}
              getOptionLabel={(option) => option.brandName}
              value={
                brands.find(
                  (brand: Brand) => brand.brandId === updatedProduct.brandId
                ) || null
              }
              onChange={(event, newValue) => {
                setUpdatedProduct((prev: any) => ({
                  ...prev,
                  brandId: newValue ? newValue.brandId : "",
                }));
              }}
              renderInput={(params) => (
                <TextField {...params} label="Brand" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={8} sx={{ mt: 2 }}>
            <TextField
              autoFocus
              margin="dense"
              label="Product Description"
              name="description"
              fullWidth
              value={updatedProduct?.description || ""}
              onChange={handleFieldChange}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <Autocomplete
                options={categories}
                getOptionLabel={(option) => `${option.categoryName}`}
                value={
                  categories.find(
                    (category: Category) =>
                      category.categoryId === updatedProduct.categoryId
                  ) || null
                }
                onChange={(event, newValue) => {
                  setUpdatedProduct((prevData: any) => ({
                    ...prevData,
                    categoryId: newValue ? newValue.categoryId : "",
                  }));
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Category" variant="outlined" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sx={{ mt: 2 }}>
            <TextField
              label="Price"
              type="number"
              name="price"
              value={updatedProduct ? updatedProduct.price : ""}
              variant="outlined"
              onChange={handleFieldChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sx={{ mt: 2 }}>
            <TextField
              label="Suggested Retail Price"
              type="number"
              name="suggestedRetailPrice"
              value={updatedProduct ? updatedProduct.suggestedRetailPrice : ""}
              onChange={handleFieldChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={4} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <Select
                labelId="supplier-label"
                name="supplierId"
                id="supplier"
                label="supplier"
                value={updatedProduct?.supplierId || ""}
                onChange={handleFieldChange}
              >
                {suppliers.map((el: Supplier) => (
                  <MenuItem key={el.supplierId} value={el.supplierId}>
                    {el.supplierName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4} sx={{ mt: 2 }}>
            <TextField
              label="Quantity"
              type="number"
              name="itemQuantity"
              value={updatedProduct ? updatedProduct.itemQuantity : ""}
              variant="outlined"
              onChange={handleFieldChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={4} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="size-label">Size</InputLabel>
              <Select
                labelId="size-label"
                name="size"
                id="size"
                label="Size"
                value={updatedProduct?.sizeId || ""}
                onChange={handleFieldChange}
              >
                {sizes.map((el: Size) => (
                  <MenuItem key={el.sizeId} value={el.sizeId}>
                    {el.size}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (selectedProduct) {
              handleUpdateProduct();
              setOpenUpdateDialog(false);
            }
          }}
          color="success"
          variant="contained"
          size="large"
          disabled={isSame}
        >
          Update
        </Button>
        <Button
          onClick={() => setOpenUpdateDialog(false)}
          color="error"
          variant="outlined"
          size="large"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function WHLSProductsTable({
  productData,
  loading,
  setOpenUpdateDialog,
  setSelectedProduct,
  setOpenDeleteDialog,
}: WHLSProductTableProps) {
  const [orderBy, setOrderBy] = useState<keyof Product>("categoryName");
  const [order, setOrder] = useState<"asc" | "desc">("asc");

  const valueHeadCells = [
    { id: "categoryName", label: "Category" },
    { id: "itemQuantity", label: "Quantity" },
    { id: "brandName", label: "Brand" },
    { id: "description", label: "Description" },
    { id: "size", label: "Size" },
    { id: "price", label: "Price" },
    { id: "edit", label: "" },
    { id: "delete", label: "" },
  ];

  const handleRequestSort = (property: keyof Product) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData: Product[] = [...productData].sort((a, b) => {
    const aValue = a[orderBy] ?? "";
    const bValue = b[orderBy] ?? "";

    if (typeof aValue === "string" && typeof bValue === "string") {
      return order === "asc"
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }

    if (typeof aValue === "number" && typeof bValue === "number") {
      return order === "asc" ? aValue - bValue : bValue - aValue;
    }

    return 0;
  });

  return (
    <div className={`table-container${loading ? "blurred" : ""}`}>
      <TableContainer
        style={{
          height: "70vh",
          overflow: "auto",
          position: "relative",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead style={{ color: "#ff9900" }}>
            <TableRow>
              {valueHeadCells.map((el) => (
                <StyledTableCell key={el.id}>
                  <StyledTableSortLabel
                    active={orderBy === el.id}
                    direction={orderBy === el.id ? order : "asc"}
                    onClick={() => handleRequestSort(el.id as keyof Product)}
                  >
                    {el.label}
                  </StyledTableSortLabel>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((product) => (
              <StyledTableRow key={product._id}>
                <StyledTableCell>{product.categoryName}</StyledTableCell>
                <StyledTableCell>{product.quantity}</StyledTableCell>
                <StyledTableCell>{product.brandName}</StyledTableCell>
                <StyledTableCell>{product.description}</StyledTableCell>
                <StyledTableCell>{product.size}</StyledTableCell>
                <StyledTableCell>
                  {formatCurrency(product.price)}
                </StyledTableCell>
                <HoverStyledTableCell>
                  <EditIcon
                    color="primary"
                    onClick={() => {
                      setSelectedProduct(product);
                      setOpenUpdateDialog(true);
                    }}
                  />
                </HoverStyledTableCell>
                <HoverStyledTableCell>
                  <DeleteIcon
                    color="error"
                    onClick={() => {
                      setSelectedProduct(product);
                      setOpenDeleteDialog(true);
                    }}
                  />
                </HoverStyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  "&.MuiTableSortLabel-root": {
    color: theme.palette.common.white,
    "&:hover": {
      color: theme.palette.common.white,
    },
    "&.Mui-active": {
      color: theme.palette.common.white,
    },
    "& .MuiTableSortLabel-icon": {
      color: theme.palette.common.white,
    },
  },
}));

const HoverStyledTableCell = styled(StyledTableCell)`
  text-align: center;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05); // Light background on hover
    cursor: pointer; // Change cursor to pointer
  }
`;

export default WHLSProducts;
