import React, { useState } from "react";
import "./pages.css";
import { Alert, Button, FormControl, TextField } from "@mui/material";
import { useLogIn } from "../hooks/useLogIn";
import { Oval } from "react-loader-spinner";

function LoginPage() {
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { login, error, loading } = useLogIn();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await login(userName, password);
  };

  return (
    <div className="login-page">
      {loading && (
        <div className="loader-overlay">
          <Oval color="blue" height={50} width={50} />
        </div>
      )}
      <div className="form-container">
        <form className="form">
          <FormControl fullWidth>
            <TextField
              label="Username"
              name="userName"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <TextField
              label="Password"
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          <Button
            disabled={loading}
            variant="contained"
            color="success"
            sx={{ mt: 3 }}
            onClick={handleSubmit}
          >
            Login
          </Button>
          {error && (
            <Alert
              variant="outlined"
              severity="error"
              style={{ marginTop: "16px" }}
            >
              {error}
            </Alert>
          )}
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
