import {
  Dialog,
  DialogContent,
  DialogTitle,
  Autocomplete,
  Grid,
  TextField,
  Button,
  FormControl,
  Box,
  DialogActions,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import "../../../pages/pages.css";
import AddIcon from "@mui/icons-material/Add";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Bus } from "../../../constants/models/bus";
import { Product } from "../../../constants/models/product";
import Loader from "../../Loader";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import RemoveIcon from "@mui/icons-material/Remove";
import "../../style.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";

function GiveProductsModal({
  open,
  onClose,
  bus,
  onDone,
}: GiveProductsModalProps) {
  const { user } = useAuthContext();
  const [formData, setFormData] = useState([
    { product: null as Product | null, quantity: "", dateGiven: dayjs() },
  ]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);

  const minDate = bus.lastAudited
    ? dayjs(bus.lastAudited).add(2, "day")
    : dayjs();

  const getProducts = async () => {
    if (user) {
      try {
        const res = await axios.get("/products/get-products", {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        const sortedData = res.data.sort((a: Product, b: Product) => {
          if (a.description < b.description) return -1;
          if (a.description > b.description) return 1;
          return b.quantity - a.quantity;
        });

        setProducts(sortedData);
        setLoading(false);
      } catch (error: any) {
        toast.error(error.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    const allValid = formData.every(
      (item) =>
        item.product &&
        item.quantity &&
        item.dateGiven &&
        parseInt(item.quantity, 10) > 0
    );

    setIsValid(allValid);
  }, [formData]);

  const handleProductChange = (
    event: React.SyntheticEvent,
    value: Product | null,
    index: number
  ) => {
    setFormData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], product: value };
      return newData;
    });
  };

  const handleQuantityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = event.target as HTMLInputElement;
    setFormData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], quantity: value };
      return newData;
    });
  };

  const handleDateChange = (date: Dayjs | null, index: number) => {
    setFormData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], dateGiven: date || dayjs() };
      return newData;
    });
  };

  const addProductRow = () => {
    setFormData((prevData) => [
      ...prevData,
      { product: null, quantity: "", dateGiven: dayjs() },
    ]);
  };

  const removeProductRow = (index: number) => {
    setFormData((prevFormData) => prevFormData.filter((_, i) => i !== index));
  };

  const handleSend = async () => {
    try {
      setLoading(true);
      for (const item of formData) {
        if (item.product && item.quantity) {
          const dataToSend = {
            productId: item.product.productId,
            quantity: item.quantity,
            busId: bus.busId,
            addedBy: user!.userId,
            dateGiven: item.dateGiven.toISOString(),
          };

          if (user) {
            const response = await axios.post(
              "/bus/add-to-bus-inventory",
              dataToSend,
              { headers: { Authorization: `Bearer ${user.token}` } }
            );

            if (response.status !== 200) {
              throw new Error(
                `Failed to add product ${item.product.description}`
              );
            }
          }
        }
      }
      setLoading(false);
      toast.success(`Products added to ${bus.busName} inventory successfully!`);
      setFormData([{ product: null, quantity: "", dateGiven: dayjs() }]);
      onDone();
      onClose();
    } catch (error: any) {
      toast.error(`Failed to add products to inventory: ${error.message}`);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        style: {
          width: "1250px",
          maxWidth: "90vw",
          margin: "auto",
          maxHeight: "80vh",
        },
      }}
    >
      <DialogTitle>Add To Busses Inventory</DialogTitle>
      <DialogContent
        dividers
        sx={{
          maxHeight: "60vh",
          overflowY: "auto",
        }}
      >
        <Loader loading={loading} />
        <TransitionGroup>
          {formData.map((item, index) => (
            <CSSTransition key={index} timeout={300} classNames="fade">
              <Grid
                container
                spacing={2}
                key={index}
                alignItems="center"
                sx={{ mt: 1 }}
              >
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <DatePicker
                      value={item.dateGiven}
                      onChange={(date) => handleDateChange(date, index)}
                      minDate={minDate}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={7}>
                  <Autocomplete
                    options={products}
                    getOptionLabel={(option) =>
                      `${option.size || ""} - ${option.brandName || ""} ${
                        option.description || ""
                      }`
                    }
                    value={item.product}
                    onChange={(event, newValue) =>
                      handleProductChange(event, newValue, index)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Product"
                        variant="outlined"
                      />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value?._id
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Quantity"
                    type="number"
                    value={item.quantity}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleQuantityChange(event, index)
                    }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                {formData.length === index + 1 && (
                  <Grid item xs={1}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <AddIcon onClick={addProductRow} className="add-icon" />
                      <RemoveIcon
                        className="remove-icon"
                        onClick={() => {
                          if (index > 0) {
                            removeProductRow(index);
                          }
                        }}
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSend}
          variant="contained"
          color="success"
          style={{ marginTop: 16 }}
          size="large"
          disabled={!isValid}
        >
          Submit
        </Button>
        <Button
          onClick={onClose}
          variant="outlined"
          color="error"
          style={{ marginTop: 16, marginLeft: 16 }}
          size="large"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default GiveProductsModal;
