import React from "react";
import { Oval } from "react-loader-spinner";

function Loader({ loading }: any) {
  return (
    <div className="">
      {loading && (
        <div className="loader-overlay">
          <Oval color="blue" height={50} width={50} />
        </div>
      )}
    </div>
  );
}

export default Loader;
