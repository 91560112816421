import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Grid,
  DialogActions,
} from "@mui/material";
import IosShareTwoToneIcon from "@mui/icons-material/IosShareTwoTone";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Oval } from "react-loader-spinner";
import toast from "react-hot-toast";
import axios from "axios";
import "../../pages/pages.css";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteForeverTwoToneIcon from "@mui/icons-material/DeleteForeverTwoTone";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { useNavigate } from "react-router-dom";
import { Bus } from "../../constants/models/bus";
import { useAuthContext } from "../../hooks/useAuthContext";
import GiveProductsModal from "./modals/GiveProductsModal";
import { Product, ProductArray } from "../../constants/models/product";
import { formatDate } from "../../constants/utils";
import HistoryIcon from "@mui/icons-material/History";
import { InventoryLog } from "../../constants/models/inventoryLog";
import { User } from "../../constants/models/user";
import Loader from "../Loader";

function BusPage({ bus }: BusPageProps) {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [productData, setProductData] = useState<ProductArray[]>([]);
  const [loading, setLoading] = useState(true);
  const [openGiveDialog, setOpenGiveDialog] = useState(false);
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductArray | null>(
    null
  );
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [quantity, setQuantity] = useState(0);

  const getBusData = async () => {
    if (user) {
      try {
        if (bus && bus.busId) {
          const res = await axios.get(`/bus/get-bus-inventory/${bus.busId}`, {
            headers: { Authorization: `Bearer ${user.token}` },
          });
          const data: Bus = res.data;
          const sortedProducts = (
            data.products as unknown as ProductArray[]
          ).sort((a, b) => {
            const dateA = new Date(a.dateAdded).getTime();
            const dateB = new Date(b.dateAdded).getTime();
            return dateB - dateA;
          });

          setProductData(sortedProducts);
        } else {
          toast.error("Bus is not available");
        }
      } catch (error: any) {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const delay = 100;

      setLoading(true);

      const timeoutId = setTimeout(async () => {
        await getBusData();
        setLoading(false);
      }, delay);

      return () => clearTimeout(timeoutId);
    };

    fetchData();
  }, [user, bus]);

  const handleUpdateInventory = async (updatedProduct: ProductArray) => {
    if (user) {
      try {
        await axios.post(
          `/bus/update-product-in-bus`,
          {
            busId: bus.busId,
            productId: updatedProduct.productId._id,
            quantity: updatedProduct.quantity,
          },
          { headers: { Authorization: `Bearer ${user.token}` } }
        );
        toast.success("Product updated successfully");
        setOpenUpdateDialog(false);
        await getBusData();
      } catch (error: any) {
        toast.error(error.message);
      }
    }
  };

  const handleDeleteProduct = async () => {
    if (user) {
      try {
        await axios.post(
          `/bus/delete-from-bus-inventory`,
          {
            busId: bus.busId,
            productId: selectedProduct!.productId._id,
            deletedBy: user.userId,
            quantity: selectedProduct!.quantity,
          },
          { headers: { Authorization: `Bearer ${user.token}` } }
        );
        toast.success("Product deleted successfully");
        setOpenDeleteDialog(false);
        await getBusData();
      } catch (error: any) {
        toast.error(error.message);
      }
    }
  };

  const today = new Date();
  const lastAudited = new Date(bus.lastAudited);
  const fourteenDaysInMilliseconds = 14 * 24 * 60 * 60 * 1000;
  const nextAuditDate = new Date(
    lastAudited.getTime() + fourteenDaysInMilliseconds
  );
  const auditDisabled = today < nextAuditDate || today > nextAuditDate;
  const auditDatePassed = today > nextAuditDate;
  return (
    <div>
      {loading && (
        <div className="loader-overlay">
          <Oval color="blue" height={50} width={50} />
        </div>
      )}
      <h1
        style={
          auditDatePassed
            ? { color: "red", height: "5vh" }
            : { color: "black", height: "5vh" }
        }
      >
        {`Next Audit: ${formatDate(nextAuditDate)}`}{" "}
      </h1>
      <div className="buttons" style={{ height: "5vh" }}>
        <Button
          variant="contained"
          color="success"
          onClick={() => setOpenGiveDialog(true)}
          size="large"
        >
          <IosShareTwoToneIcon style={{ marginRight: "5px" }} />
          Give Product
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={async () => {
            setLoading(true);
            await getBusData();
            setLoading(false);
          }}
        >
          <RefreshIcon style={{ marginRight: "5px" }} />
          Refresh
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={() =>
            navigate("/audit-bus", { state: { bus, productData } })
          }
          disabled={!auditDisabled}
        >
          <FindInPageIcon style={{ marginRight: "5px" }} />
          Audit Bus
        </Button>
        <Button
          variant="contained"
          color="info"
          size="large"
          onClick={() => setOpenHistoryDialog(true)}
        >
          <HistoryIcon style={{ marginRight: "5px" }} />
          Inventory Log
        </Button>
        <GiveProductsModal
          open={openGiveDialog}
          onClose={() => setOpenGiveDialog(false)}
          bus={bus}
          onDone={getBusData}
        />
      </div>
      <ProductTable
        productData={productData}
        setOpenUpdateDialog={setOpenUpdateDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        setSelectedProduct={setSelectedProduct}
        loading={loading}
      />
      <UpdateDialog
        openUpdateDialog={openUpdateDialog}
        setOpenUpdateDialog={setOpenUpdateDialog}
        selectedProduct={selectedProduct}
        setQuantity={setQuantity}
        handleUpdateInventory={handleUpdateInventory}
        bus={bus}
        quantity={quantity}
      />
      <InventoryHistoryDialog
        openHistoryDialog={openHistoryDialog}
        setOpenHistoryDialog={setOpenHistoryDialog}
        bus={bus}
        user={user}
      />
      <DeleteDialog
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        selectedProduct={selectedProduct}
        handleDeleteProduct={handleDeleteProduct}
        bus={bus}
      />
    </div>
  );
}

export function InventoryHistoryDialog({
  openHistoryDialog,
  setOpenHistoryDialog,
  bus,
  user,
}: InventoryHistoryProps) {
  const [inventoryLog, setInventoryLog] = useState<InventoryLog[]>([]);
  const [loading, setLoading] = useState(true);

  const getHistory = async () => {
    try {
      const response = await axios.get(`/bus/get-inventory-logs/${bus.busId}`, {
        headers: { Authorization: `Bearer ${user!.token}` },
      });
      setInventoryLog(response.data);
    } catch (error: any) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (openHistoryDialog) {
      const fetchData = async () => {
        const delay = 100;

        setLoading(true);

        const timeoutId = setTimeout(async () => {
          await getHistory();
          setLoading(false);
        }, delay);

        return () => clearTimeout(timeoutId);
      };

      fetchData();
    }
  }, [openHistoryDialog]);

  const handleButtonClick = (event: any) => {
    setOpenHistoryDialog(false);
  };

  return (
    <Dialog
      open={openHistoryDialog}
      onClose={setOpenHistoryDialog}
      PaperProps={{
        style: {
          width: "1500px",
          maxWidth: "90vw",
          maxHeight: "90vh",
        },
      }}
    >
      <DialogTitle>Inventory Log for {bus.busName}</DialogTitle>
      <DialogContent>
        <Loader loading={loading} />
        <div className="buttons">
          <Button
            color="primary"
            variant="outlined"
            size="large"
            onClick={async () => {
              setLoading(true);
              await getHistory();
              setLoading(false);
            }}
          >
            <RefreshIcon />
            Refresh
          </Button>
        </div>
        <TableContainer style={{ height: "625px", overflow: "auto" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Description</StyledTableCell>
                <StyledTableCell>Product</StyledTableCell>
                <StyledTableCell>Quantity</StyledTableCell>
                <StyledTableCell>Added By</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inventoryLog.map((log) => (
                <StyledTableRow key={log.logId}>
                  <StyledTableCell>{formatDate(log.date)}</StyledTableCell>
                  <StyledTableCell>{log.action}</StyledTableCell>
                  <StyledTableCell>
                    {log.productId
                      ? log.productId.description
                      : "No description available"}
                  </StyledTableCell>
                  <StyledTableCell>{log.quantity}</StyledTableCell>
                  <StyledTableCell>{log.modifiedBy.userName}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={handleButtonClick}
          size="large"
          variant="contained"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function UpdateDialog({
  openUpdateDialog,
  setOpenUpdateDialog,
  selectedProduct,
  setQuantity,
  quantity,
  handleUpdateInventory,
}: UpdateDialogProps) {
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (selectedProduct) {
      setQuantity(selectedProduct.quantity);
    }
  }, [selectedProduct, setQuantity]);

  useEffect(() => {
    if (quantity !== (selectedProduct?.quantity ?? 0)) {
      setIsUpdated(true);
    } else {
      setIsUpdated(false);
    }
  }, [quantity, selectedProduct?.quantity]);

  const handleQuantityChange = (event: any) => {
    const newQuantity = parseInt(event.target.value, 10);
    setQuantity(newQuantity);
  };
  return (
    <Dialog
      open={openUpdateDialog}
      onClose={() => setOpenUpdateDialog(false)}
      PaperProps={{
        style: {
          width: "800px",
          maxWidth: "90vw",
          maxHeight: "90vh",
        },
      }}
    >
      <DialogTitle>Update Product Quantity</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mb={3} alignItems="center">
          <Grid item xs={8}>
            <TextField
              autoFocus
              margin="dense"
              label="Product Description"
              fullWidth
              value={
                selectedProduct
                  ? selectedProduct.size.size +
                      " of " +
                      selectedProduct.brand.brandName ??
                    "" + " " + selectedProduct.productId.description ??
                    ""
                  : ""
              }
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Quantity"
              type="number"
              name="quantity"
              value={quantity}
              onChange={handleQuantityChange}
              variant="outlined"
              fullWidth
              inputProps={{ min: 0 }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpenUpdateDialog(false)}
          color="error"
          variant="outlined"
          size="large"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (selectedProduct) {
              handleUpdateInventory(selectedProduct);
            }
          }}
          color="success"
          variant="contained"
          size="large"
          disabled={!isUpdated}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function DeleteDialog({
  setOpenDeleteDialog,
  openDeleteDialog,
  selectedProduct,
  bus,
  handleDeleteProduct,
}: DeleteDialogProps) {
  return (
    <Dialog
      open={openDeleteDialog}
      onClose={() => setOpenDeleteDialog(false)}
      PaperProps={{
        style: {
          width: "800px",
          maxWidth: "90vw",
          maxHeight: "90vh",
        },
      }}
    >
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        <p style={{ marginBottom: "10px" }}>
          Are you sure you want to remove{" "}
          <strong>
            {selectedProduct
              ? ` (${selectedProduct.quantity}) ${
                  selectedProduct.size.size
                }(s) of ${selectedProduct.brand.brandName ?? ""} ${
                  selectedProduct.productId.description
                } `
              : "this product"}
          </strong>
          from the {bus.busName}' inventory?
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (selectedProduct) {
              handleDeleteProduct();
              setOpenDeleteDialog(false);
            }
          }}
          color="error"
          variant="contained"
          size="large"
        >
          Delete
        </Button>
        <Button
          onClick={() => setOpenDeleteDialog(false)}
          color="error"
          variant="outlined"
          size="large"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function ProductTable({
  productData,
  loading,
  setOpenUpdateDialog,
  setSelectedProduct,
  setOpenDeleteDialog,
}: ProductTableProps) {
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState<"asc" | "desc">("asc");

  const valueHeadCells = [
    { id: "dateAdded", label: "Added" },
    { id: "brandName", label: "Brand" },
    { id: "description", label: "Description" },
    { id: "quantity", label: "On Hand" },
    { id: "size", label: "Size" },
    { id: "price", label: "Price" },
    { id: "total", label: "Total" },
    { id: "edit", label: "" },
    { id: "delete", label: "" },
  ];

  const handleRequestSort = (property: keyof ProductArray) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData: ProductArray[] = [...productData].sort((a: any, b: any) => {
    const aValue = a[orderBy] ?? "";
    const bValue = b[orderBy] ?? "";

    if (orderBy === "dateAdded") {
      const dateA = new Date(aValue);
      const dateB = new Date(bValue);
      return order === "asc"
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime();
    }

    if (typeof aValue === "string" && typeof bValue === "string") {
      return order === "asc"
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }

    if (typeof aValue === "number" && typeof bValue === "number") {
      return order === "asc" ? aValue - bValue : bValue - aValue;
    }

    return 0;
  });

  return (
    <div className={`table-container${loading ? "blurred" : ""}`}>
      <TableContainer
        style={{
          height: "65vh",
          overflow: "auto",
          position: "relative",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead style={{ color: "#ff9900" }}>
            <TableRow>
              {valueHeadCells.map((el) => (
                <StyledTableCell key={el.id}>
                  <StyledTableSortLabel
                    active={orderBy === el.id}
                    direction={orderBy === el.id ? order : "asc"}
                    onClick={() =>
                      handleRequestSort(el.id as keyof ProductArray)
                    }
                  >
                    {el.label}
                  </StyledTableSortLabel>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((product, index) => (
              <StyledTableRow
                key={product.productId?._id + index ?? "unknown-id"}
              >
                <StyledTableCell>
                  {formatDate(product.dateAdded)}
                </StyledTableCell>
                <StyledTableCell>
                  {product.brand?.brandName ?? "Unknown"}
                </StyledTableCell>
                <StyledTableCell>
                  {product.productId?.description ?? "No description available"}
                </StyledTableCell>
                <StyledTableCell>{product.quantity ?? 0}</StyledTableCell>
                <StyledTableCell>
                  {product.size?.size ?? "Unknown"}
                </StyledTableCell>
                <StyledTableCell>{`$ ${
                  product.productId?.price?.toFixed(2) ?? "0.00"
                }`}</StyledTableCell>
                <StyledTableCell>{`$ ${
                  (product.productId?.price ?? 0) * (product.quantity ?? 0)
                }`}</StyledTableCell>
                <StyledTableCell>
                  <EditTwoToneIcon
                    color="primary"
                    onClick={() => {
                      setSelectedProduct(product);
                      setOpenUpdateDialog(true);
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <DeleteForeverTwoToneIcon
                    color="error"
                    onClick={() => {
                      setSelectedProduct(product);
                      setOpenDeleteDialog(true);
                    }}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  "&.MuiTableSortLabel-root": {
    color: theme.palette.common.white,
    "&:hover": {
      color: theme.palette.common.white,
    },
    "&.Mui-active": {
      color: theme.palette.common.white,
    },
    "& .MuiTableSortLabel-icon": {
      color: theme.palette.common.white,
    },
  },
}));

export default BusPage;
