import {
  Button,
  Dialog,
  Checkbox,
  TextField,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  FormControl,
} from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Oval } from "react-loader-spinner";
import { useAuthContext } from "../../hooks/useAuthContext";
import { User } from "../../constants/models/user";
import { Role } from "../../constants/models/role";

function ManageUsers({ open, onClose }: ManageUsersProps) {
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    userName: "",
    password: "",
    roleId: "",
    requirePasswordChange: true,
  });

  const getUsers = async () => {
    try {
      const res = await axios.get("/user/get-users", {
        headers: { Authorization: `Bearer ${user!.token}` },
      });
      const data = res.data;
      setUsers(data);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const getRoles = async () => {
    try {
      const res = await axios.get("/data/get-roles", {
        headers: { Authorization: `Bearer ${user!.token}` },
      });
      const data = res.data;
      setRoles(data);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const delay = 100;

      setLoading(true);

      const timeoutId = setTimeout(async () => {
        await getRoles();
        await getUsers();
        setLoading(false);
      }, delay);

      return () => clearTimeout(timeoutId);
    };

    fetchData();
  }, []);

  const handleAddUser = async () => {
    try {
      await axios.post("/user/add-user", formData, {
        headers: { Authorization: `Bearer ${user!.token}` },
      });
      toast.success("User added successfully");
      onClose();
      setOpenAddDialog(false);
      setFormData({
        name: "",
        userName: "",
        password: "",
        roleId: "",
        requirePasswordChange: true,
      });
      await getUsers();
    } catch (error) {
      toast.error("Failed to add user");
    }
  };

  const handleInputChange = (field: string) => (e: any) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        PaperProps={{
          style: {
            height: "600px",
            overflow: "hidden",
          },
        }}
      >
        <DialogTitle>Manage Users</DialogTitle>
        <DialogContent>
          {loading && (
            <div className="loader-overlay">
              <Oval color="blue" height={50} width={50} />
            </div>
          )}
          <div className="buttons">
            <Button
              variant="contained"
              color="success"
              onClick={() => setOpenAddDialog(true)}
            >
              <AddIcon />
              Add User
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={async () => {
                setLoading(true);
                await getUsers();
                setLoading(false);
              }}
            >
              <RefreshIcon />
              Refresh
            </Button>
          </div>
          <TableContainer
            style={{
              height: "400px",
              overflow: "auto",
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <TableSortLabel>Username</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel>Role</TableSortLabel>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((el) => (
                  <StyledTableRow key={el._id}>
                    <StyledTableCell>{`${el.name}`}</StyledTableCell>
                    <StyledTableCell>{`${el.roleName}`}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            onClick={onClose}
            color="error"
            variant="outlined"
            style={{ marginRight: "16px", marginTop: "16px" }}
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        PaperProps={{
          style: {
            width: "500px",
            maxWidth: "90vw",
            margin: "auto",
          },
        }}
      >
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            name="name"
            fullWidth
            value={formData.name}
            onChange={handleInputChange("name")}
            style={{ marginBottom: "10px" }}
          />
          <TextField
            autoFocus
            margin="dense"
            label="Username"
            name="userName"
            fullWidth
            value={formData.userName}
            onChange={handleInputChange("userName")}
            style={{ marginBottom: "10px" }}
          />
          <TextField
            autoFocus
            margin="dense"
            label="Password"
            type="password"
            name="password"
            fullWidth
            value={formData.password}
            onChange={handleInputChange("password")}
            style={{ marginBottom: "16px" }}
          />
          <FormControl fullWidth style={{ marginBottom: "16px" }}>
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              labelId="role-label"
              id="role"
              label="Role"
              value={formData.roleId}
              name="roleId"
              onChange={handleInputChange("roleId")}
            >
              {roles.map((el) => (
                <MenuItem key={el._id} value={el._id}>
                  {el.roleName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.requirePasswordChange}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    requirePasswordChange: e.target.checked,
                  }))
                }
              />
            }
            label="Require Password Change"
            style={{ marginBottom: "16px" }}
          />
          <div style={{ marginTop: "16px" }}>
            <Button
              onClick={handleAddUser}
              color="success"
              variant="contained"
              style={{ marginRight: "16px" }}
            >
              Add
            </Button>
            <Button
              onClick={() => setOpenAddDialog(false)}
              color="error"
              variant="outlined"
            >
              Cancel
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default ManageUsers;
