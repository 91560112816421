import React, { useState, useEffect } from "react";
import "./pages.css";
import { Box, Tab, Tabs } from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import BusPage from "../components/busPage/BusPage";
import { useAuthContext } from "../hooks/useAuthContext";
import Header from "../components/navbar/Header";
import { Bus } from "../constants/models/bus";
import { Oval } from "react-loader-spinner";

function Busses() {
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(true);
  const [busses, setBusses] = useState<Bus[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const getBusses = async () => {
    if (user) {
      try {
        const res = await axios.get("/bus/get-busses", {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        const data = res.data;
        setBusses(data);
      } catch (error: any) {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const delay = 100;

      setLoading(true);

      const timeoutId = setTimeout(async () => {
        await getBusses();
        setLoading(false);
      }, delay);

      return () => clearTimeout(timeoutId);
    };

    fetchData();
  }, []);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tab-panel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <Header open={open} toggleDrawer={toggleDrawer} title={"Busses"} />
      <div
        style={{
          marginTop: "80px",
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
          height: "calc(100vh - 80px)",
        }}
      >
        {loading && (
          <div className="loader-overlay">
            <Oval color="blue" height={50} width={50} />
          </div>
        )}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            aria-label="bus tabs"
          >
            {busses.map((el, index) => (
              <Tab
                key={el.busId}
                label={el.busName}
                id={`tab-${index}`}
                aria-controls={`tab-panel-${index}`}
              />
            ))}
          </Tabs>
        </Box>

        {busses.map((el, index) => (
          <TabPanel value={selectedTab} index={index} key={el.busId}>
            <BusPage bus={el} />
          </TabPanel>
        ))}
      </div>
    </Box>
  );
}

export default Busses;
